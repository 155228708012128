import {FC, PropsWithChildren} from 'react';
import getConfig from 'next/config';

import {getRandom} from 'client/utils/view-helpers/array-helpers';
import {FeaturedCollectionSlotIds} from 'client/views/components/home-page/types/FeaturedCollectionSlotIds';

import {useHomeScreenCMS, IHomescreenData} from '../hooks/use-home-screen-cms';
import {IFirestoreFeaturedCollection} from '../types';

import * as featuredCollectionHelpers from './featured-collection-helper';
import FeaturedCollectionSwimlane from './featured-collection-swimlane';

interface IFeaturedCollectionProps {
    slotId: FeaturedCollectionSlotIds;
    lazyload?: boolean;
}

const getDefaultCollection = (): IFirestoreFeaturedCollection => {
    const defaultCollection = getConfig().publicRuntimeConfig.defaultFeatureCollection;

    return {
        collectionId: Number(defaultCollection.id),
        enabled: true,
        promo: undefined,
        slotId: FeaturedCollectionSlotIds.FIRST
    };
};

const getSlotCollections = (
    data: IHomescreenData | null,
    slotId: FeaturedCollectionSlotIds
): IFirestoreFeaturedCollection[] => {
    const collections = data?.featuredCollection || [];

    const slotCollections = collections.filter((c) => c.slotId === slotId && c.enabled);

    if (slotId === FeaturedCollectionSlotIds.FIRST && slotCollections.length === 0) {
        return [getDefaultCollection()];
    }

    return slotCollections;
};

const getCollection = (slotId: FeaturedCollectionSlotIds, collections: IFirestoreFeaturedCollection[]) => {
    const collectionId = featuredCollectionHelpers.getFeaturedCollectionId(slotId);

    if (collectionId) {
        const collection = collections.find((x) => x.collectionId === collectionId);

        if (collection) {
            return collection;
        }
    }

    const collection = getRandom(collections);

    if (collection) {
        featuredCollectionHelpers.setFeaturedCollectionId(slotId, collection.collectionId);
    }

    return collection;
};

const FirebaseCollectionSwimlane: FC<PropsWithChildren<PropsWithChildren<IFeaturedCollectionProps>>> = ({
    slotId,
    lazyload = false
}) => {
    const {data} = useHomeScreenCMS();

    const collections = getSlotCollections(data, slotId);
    const collection = getCollection(slotId, collections);

    const collectionId = collection?.collectionId;

    if (!collectionId) {
        return null;
    }

    return <FeaturedCollectionSwimlane collectionId={collectionId} lazyload={lazyload} promo={collection?.promo} />;
};

export default FirebaseCollectionSwimlane;
