import {gql} from '@apollo/client';

export const getSubNav = gql`
    query GetSubNav {
        allCmsSharedSubNavigationConfig(
            sort: {publishedAt: DESC}
            limit: 1
            where: {site: {eq: "AO"}, _: {is_draft: false}}
        ) {
            title
            site
            publishedAt
            links {
                text
                href
                locationType
            }
            overrideUrls
        }
    }
`;
