import {FulfillmentHeader} from '@hy-vee/navigation';
import {GlobalNavigationFooter} from '@hy-vee/global-navigation';
import {NextSeo} from 'next-seo';
import {FC, ReactNode, PropsWithChildren} from 'react';
import {PrivacyMask} from '@hy-vee/design-system';

import SubNavigation from 'client/views/components/sub-navigation/sub-navigation';
import {assignParentUrl} from 'client/utils/iframe-helpers';
import {getPreviousPath} from 'client/utils/url-utils';

import {FULFILLMENT_HEADER} from '../../enums/header-types';
import {HeaderChildren, SkipNavigation} from '../../styles/header-styles';
import {StyledFixedContainer, StyledRelativeContainer} from '../../styles/style-constants';
import {getLogoutUrl} from '../../utils/redirect-to-login';
import {useAislesOnlineRouter} from '../../hooks/use-aisles-online-router';
import {ReservationDrawer} from '../reservation-drawer';

import BackButton from './back-button/back-button';
import TopLevelModals from './page-layout/top-level-modals';
import {GlobalNavigationHeaderWrapper} from './global-navigation-header-wrapper';
import styles from './header-page.module.css';

const getFirstNameForFulfillmentHeader = ({handle, firstName}: {handle: any; firstName: string}) =>
    firstName ? firstName : handle;

interface IHeaderPageProps {
    background?: boolean;
    customer: any;
    children: JSX.Element | ReactNode;
    enableTopLevelModal?: boolean;
    headerType?: string;
    hideCartFlyout?: boolean;
    title: string;
    store: any;
    showFooter?: boolean;
    sticky?: boolean;
}

const HeaderPage: FC<PropsWithChildren<PropsWithChildren<IHeaderPageProps>>> = ({
    background = false,
    customer,
    children,
    enableTopLevelModal = true,
    headerType,
    hideCartFlyout = false,
    title,
    store,
    showFooter = true,
    sticky = false
}) => {
    const router = useAislesOnlineRouter();
    const HeaderStickyContainer = sticky ? StyledFixedContainer : StyledRelativeContainer;

    return (
        <>
            <NextSeo title={title} />
            <SkipNavigation href="#main">{'Skip to Main Content'}</SkipNavigation>
            <StyledRelativeContainer background={background}>
                <HeaderStickyContainer>
                    {(() => {
                        if (headerType === FULFILLMENT_HEADER) {
                            return (
                                <PrivacyMask>
                                    <FulfillmentHeader
                                        location={{location: router.asPathWithBasePath}}
                                        logoutUrl={getLogoutUrl()}
                                        user={{firstName: getFirstNameForFulfillmentHeader(customer)}}
                                    />
                                </PrivacyMask>
                            );
                        }

                        return <GlobalNavigationHeaderWrapper hideCartFlyout={hideCartFlyout} store={store} />;
                    })()}
                    {headerType === FULFILLMENT_HEADER && (
                        <BackButton
                            buttonLabel="Back"
                            onClick={() =>
                                getPreviousPath(window.location.href)
                                    ? assignParentUrl(getPreviousPath(window.location.href))
                                    : window.history.back()
                            }
                            paddingLeft="70px"
                            size="medium"
                        />
                    )}
                </HeaderStickyContainer>
                <HeaderChildren headerType={headerType} sticky={sticky}>
                    <SubNavigation />
                    <div className={styles.mainDiv} id="main">
                        {children}
                    </div>
                </HeaderChildren>
                <ReservationDrawer />
                {enableTopLevelModal && <TopLevelModals />}
                {showFooter && <GlobalNavigationFooter />}
            </StyledRelativeContainer>
        </>
    );
};

export default HeaderPage;
