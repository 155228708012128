import {FC, PropsWithChildren} from 'react';

import Error from 'pages/_error';
import {NO_HEADER} from 'client/enums/header-types';
import {useCustomer} from 'client/hooks/customer-hooks';
import HeaderPage from 'client/views/components/header-page';
import {useBasicCustomerInformation} from 'client/hooks/use-basic-customer-information-hook';

import {AISLES_ONLINE_PAGE_TITLE} from '../../../enums/page-title';

interface IPageWithCustomerDataWrapper {
    LoadingComponent: JSX.Element | null;
    showLoading?: boolean;
    includeProductCardData?: boolean;
    ignoreCustomerInfoLoading?: boolean;
    showFooter?: boolean;
    title?: string;
    headerType?: string;
}

const PageWithCustomerDataWrapper: FC<PropsWithChildren<PropsWithChildren<IPageWithCustomerDataWrapper>>> = ({
    children,
    showLoading,
    LoadingComponent,
    includeProductCardData,
    ignoreCustomerInfoLoading,
    title,
    showFooter = true,
    headerType
}) => {
    const customer = useCustomer();
    const {loading, error} = useBasicCustomerInformation(includeProductCardData);

    if (error) {
        return <Error err={error} hasGetInitialPropsRun={false} />;
    }

    const showLoadingStateOrChildren =
        (!ignoreCustomerInfoLoading && loading) || showLoading ? LoadingComponent : children;

    return headerType === NO_HEADER ? (
        showLoadingStateOrChildren
    ) : (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        <HeaderPage
            customer={customer}
            headerType={headerType}
            showFooter={showFooter}
            sticky
            title={title || AISLES_ONLINE_PAGE_TITLE}
        >
            {showLoadingStateOrChildren}
        </HeaderPage>
    );
};

export default PageWithCustomerDataWrapper;
