import {FC, PropsWithChildren, forwardRef, useEffect, useState} from 'react';
import {NonNullableProps} from '@hy-vee/ts-utils';
import {useSmoothScroll} from 'react-smooth-scroll-hook';
import {CaretLeftIcon, CaretRightIcon} from '@hy-vee/icons';
import {LinkButton} from '@hy-vee/web-core/lib/components/button';

import {ElementProps} from '../../../types/html-element-props';
import {useClassnames} from '../../../hooks/use-classnames';

import styles from './scroll-icon.module.css';

interface IScrollIconProps {
    direction: NonNullableProps<'left' | 'right'>;
}

type Ref = any;

const ScrollIconWrapper: FC<PropsWithChildren<PropsWithChildren<ElementProps<HTMLDivElement> & IScrollIconProps>>> = ({
    direction,
    ...props
}) => {
    const classnames = useClassnames(styles, {
        left: direction === 'left',
        right: direction === 'right',
        scrollIconWrapper: true
    });

    return <div {...props} className={classnames} />;
};

const ScrollIcon = forwardRef<Ref, IScrollIconProps>((props, ref: any) => {
    const {direction} = props;
    const scrollModifier = 0.85;

    const [scrollWidth, setScrollWidth] = useState(0);

    useEffect(() => {
        setScrollWidth(Math.floor(ref.current?.clientWidth * scrollModifier));
    }, [ref]);

    const {scrollTo, reachedTop, reachedBottom} = useSmoothScroll({
        direction: 'x',
        ref,
        speed: 50,
        threshold: 5
    });

    if (direction === 'left' && scrollWidth) {
        return !reachedTop ? (
            <ScrollIconWrapper direction={direction} style={{left: '0%'}}>
                <LinkButton
                    aria-hidden="true"
                    aria-label="scroll left"
                    className={styles.styledLinkButton}
                    data-testid="scroll-icon-left-button"
                    onClick={() => {
                        scrollTo(scrollWidth * -1);
                    }}
                >
                    <span className={styles.styledIconWrapper}>
                        <CaretLeftIcon className={styles.styledSvg} color="inherit" size="medium" />
                    </span>
                </LinkButton>
            </ScrollIconWrapper>
        ) : null;
    }

    if (direction === 'right' && scrollWidth) {
        return !reachedBottom ? (
            <ScrollIconWrapper direction={direction} style={{right: '0%'}}>
                <LinkButton
                    aria-hidden="true"
                    aria-label="scroll right"
                    className={styles.styledLinkButton}
                    data-testid="scroll-icon-right-button"
                    onClick={() => {
                        scrollTo(scrollWidth);
                    }}
                >
                    <span className={styles.styledIconWrapper}>
                        <CaretRightIcon className={styles.styledSvg} color="inherit" size="medium" />
                    </span>
                </LinkButton>
            </ScrollIconWrapper>
        ) : null;
    }

    return null;
});

export default ScrollIcon;
