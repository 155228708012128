import {TWO_LEGGED_AUTH_TYPE} from 'client/enums/auth-types';
import {useCustomer, useCustomerData} from 'client/hooks/customer-hooks';
import {SEARCH_ON_SALE_PATH} from 'client/enums/rerouting-paths';
import {storeProductsQuery, buyAgainQuery} from 'client/graphql/queries/product-queries';
import {useCustomerDataToGetLocationId} from 'client/hooks/location-hooks';
import {EWIC_STORES, PRICING_ITEM_API} from 'client/enums/feature-toggle-names';
import {useFeatureToggle} from 'client/context/feature-toggle';

import {useGraphqlSwimlane} from '../hooks/useGraphqlSwimlane';

import StoreProductSwimlaneOptions from './store-product-swimlane-options';
import BuyAgainProductsOption from './buy-again-swimlane-options';
import HomePageSwimlane from './home-page-swimlane';

interface IOnSaleSwimlane {
    lazyload: boolean;
}

const OnSaleSwimlane = ({lazyload}: IOnSaleSwimlane) => {
    const {customerId, pickupLocationHasLocker, storeId, activeCart} = useCustomerData();
    const {fuelSaverCardUuid} = useCustomer();
    const {featureEnabled, featuresEnabled} = useFeatureToggle();
    const pricingItemApiToggle = featureEnabled(PRICING_ITEM_API);
    const {locationId, loading: locationLoading} = useCustomerDataToGetLocationId();

    const wicEnabled = featuresEnabled([EWIC_STORES]);

    const seeAllUrl = activeCart?.storeId ? SEARCH_ON_SALE_PATH : undefined;

    const options = fuelSaverCardUuid
        ? new BuyAgainProductsOption('On Sale', seeAllUrl)
        : new StoreProductSwimlaneOptions('On Sale', seeAllUrl);

    const variables = {
        itemEnabled: pricingItemApiToggle,
        locationIds: [locationId],
        onSale: true,
        pageSize: 10,
        pickupLocationHasLocker,
        retailItemEnabled: Boolean(locationId),
        storeId,
        targeted: Boolean(fuelSaverCardUuid),
        wicEnabled,
        ...(fuelSaverCardUuid ? {customerId} : {authType: TWO_LEGGED_AUTH_TYPE})
    };

    const {data, loading, fetchMore} = useGraphqlSwimlane(
        options,
        fuelSaverCardUuid ? buyAgainQuery : storeProductsQuery,
        {
            skip: pricingItemApiToggle && locationLoading,
            variables
        }
    );

    return (
        <HomePageSwimlane
            aria-label={options.getHeader()}
            data={options.getItems(data)}
            fetchMore={fetchMore}
            header={{
                seeAllUrl: options.getSeeAllUrl(),
                text: options.getHeader()
            }}
            lazyload={lazyload}
            loading={loading}
            swimlaneTestId={'on-sale-swimlane'}
        />
    );
};

export default OnSaleSwimlane;
