import {FC, PropsWithChildren} from 'react';
import getConfig from 'next/config';

import ProductSwimlane, {IProductSwimlaneProps} from 'client/views/components/swimlanes/product-swimlane';
import ProdxAislesType from 'client/enums/ProdxAislesType';

export type IProdxProductSwimlaneProps = IProductSwimlaneProps & {
    aislesId: ProdxAislesType;
    onSwimLaneView?: (products: any[] | null) => void;
};

const ProdxProductSwimlane: FC<PropsWithChildren<PropsWithChildren<IProdxProductSwimlaneProps>>> = ({
    aislesId,
    ...props
}) => {
    const getEventApiAislesId = () => {
        const {publicRuntimeConfig} = getConfig();
        const {prodx} = publicRuntimeConfig;

        return prodx.eventApi[aislesId]; // prodx.eventApi['c8bf0dca5af146b3bdf82362af6670c8']
    };

    return (
        <ProductSwimlane
            {...props}
            aisleId={getEventApiAislesId()}
            onSwimlaneView={props.onSwimlaneView}
            swimlaneDrawerStyles={{
                border: 'none'
            }}
        />
    );
};

export default ProdxProductSwimlane;
