import {FC, PropsWithChildren} from 'react';
import styled from 'styled-components';
import {LinkButton} from '@hy-vee/web-core';
import {useQuery} from '@apollo/client';
import {colors, hyvee, sizing} from '@hy-vee/themes';

import {LoyaltyCardAccountName} from 'autogen/globalTypes';
import {useCustomerUuid} from 'client/hooks/customer-hooks';
import {assignParentUrl} from 'client/utils/iframe-helpers';
import {FUEL_SAVER_PATH} from 'client/enums/rerouting-paths';
import {fuelSaverBackgroundColor} from 'client/styles/style-constants';
import {getFuelSaverBalance} from 'client/graphql/queries/fuel-saver-queries';
import {GetCustomerFuelSaverQuery, GetCustomerFuelSaverQueryVariables} from 'autogen/GetCustomerFuelSaverQuery';
import {
    getFormattedFuelSaverAmount,
    getLoyaltyCardAccountByName,
    getRewardBalance
} from 'client/utils/fuel-saver-helpers';

const RewardsContainer = styled.div`
    background-color: #f9f9f9;
    border-right: 1px solid ${colors.grey[200]};
    border-bottom: 1px solid ${colors.grey[200]};
    display: flex;
    flex-direction: column;
    transition: all 0.15s ease;
    flex-grow: 0;
    align-items: center;
    justify-content: space-between;
    min-width: 245px;
    min-height: 415px;

    @media screen and (max-width: 500px) {
        min-width: 200px;
    }

    :hover {
        cursor: pointer;
        position: relative;
        box-shadow: 0 10px 20px 5px rgba(220, 220, 220, 0.5);
    }
`;

const RewardsBorder = styled.div`
    background-color: ${fuelSaverBackgroundColor};
    background-image: url('https://8e3463198116a37cf901-4c8f9ce7667a46d130eda30090f49466.ssl.cf2.rackcdn.com/perks/images/gas-sm.png');
    background-position: 0px 20px;
    background-size: contain;
    border: 8px solid ${hyvee.primary};
    border-radius: 50%;
    height: 120px;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-bottom: 8px;
    width: 120px;
`;

const RewardText = styled.span`
    color: #fff;
    font-weight: 400;
    font-size: 38px;
`;

const BalanceText = styled.span`
    font-weight: normal;
    font-size: ${sizing[18]};
    margin-top: 4px;
    line-height: 1.25;
    text-align: center;

    @media screen and (min-width: 500px) {
        font-size: ${sizing[30]};
        margin-top: 16px;
    }
`;

const BalanceContainer = styled.div`
    align-items: center;
    display: flex;
    flex: 0.5;
    flex-direction: column;
    padding-top: 34px;

    @media screen and (min-width: 500px) {
        padding-top: 60px;
    }
`;

const FuelSaverBalance: FC<PropsWithChildren<PropsWithChildren>> = () => {
    const customerUuid = useCustomerUuid();

    const {data, loading, error} = useQuery<GetCustomerFuelSaverQuery, GetCustomerFuelSaverQueryVariables>(
        getFuelSaverBalance,
        {
            skip: !customerUuid,
            variables: {
                customerUuid
            }
        }
    );

    if (loading || error || !data) {
        return null;
    }

    const fuelSaverCard = data?.customer?.fuelSaverCard;
    const fuelSaverAccounts = fuelSaverCard?.accounts || [];

    if (!fuelSaverCard || !fuelSaverAccounts.length) {
        return null;
    }

    const fuelSaverAccount = getLoyaltyCardAccountByName(fuelSaverCard, LoyaltyCardAccountName.FUEL_SAVER_CENTS);
    const rewardBalance = getRewardBalance(fuelSaverAccount);
    const formattedFuelSaverBalance = getFormattedFuelSaverAmount(Number(rewardBalance));

    return (
        <RewardsContainer onClick={() => assignParentUrl(FUEL_SAVER_PATH)}>
            <BalanceContainer>
                <RewardsBorder>
                    <RewardText>{formattedFuelSaverBalance}</RewardText>
                </RewardsBorder>
                <BalanceText>{'Reward Balance'}</BalanceText>
            </BalanceContainer>
            <LinkButton
                as="a"
                data-testid="view-all-fuel-rewards"
                href={FUEL_SAVER_PATH}
                style={{marginBottom: '36px'}}
            >
                {'View Rewards Profile'}
            </LinkButton>
        </RewardsContainer>
    );
};

export default FuelSaverBalance;
