import {useMutation, useQuery, ApolloError, NetworkStatus} from '@apollo/client';
import {useEffect, useState} from 'react';

import {getBasicCustomerInformation} from 'client/graphql/queries/cart-queries';

import {CreateCart, CreateCartVariables} from '../../autogen/CreateCart';
import {
    GetBasicCustomerInformation,
    GetBasicCustomerInformationVariables
} from '../../autogen/GetBasicCustomerInformation';
import {createCartMutation} from '../graphql/mutations/cart-mutations';

import {useCustomer} from './customer-hooks';

export const useBasicCustomerInformation = (
    includeProductCardData?: boolean,
    createCartIfNeeded = true
): {
    error: ApolloError | undefined;
    loading: boolean;
} => {
    const [creatingCart, setCreatingCart] = useState(false);
    const customer = useCustomer();
    const {data, loading, error, networkStatus, refetch} = useQuery<
        GetBasicCustomerInformation,
        GetBasicCustomerInformationVariables
    >(getBasicCustomerInformation, {
        notifyOnNetworkStatusChange: true,
        skip: !customer.customerId,
        variables: {
            customerId: Number(customer.customerId),
            customerUuid: customer.customerUuid?.toLowerCase(),
            includeProductCardData: Boolean(includeProductCardData)
        }
    });
    const [createCart] = useMutation<CreateCart, CreateCartVariables>(createCartMutation, {
        onCompleted: () => {
            refetch();
        }
    });

    const isRefetching = networkStatus === NetworkStatus.refetch;
    const hasActiveCart = Boolean(data?.carts?.length);
    const shouldCreateNewCart =
        createCartIfNeeded && customer.customerId && !isRefetching && !loading && !error && !hasActiveCart;

    useEffect(() => {
        const createCartIfNecessary = async () => {
            if (shouldCreateNewCart) {
                setCreatingCart(true);

                await createCart({
                    variables: {
                        createCartInput: {
                            customerId: Number(customer.customerId)
                        }
                    }
                });

                setCreatingCart(false);
            }
        };

        createCartIfNecessary();
    }, [createCart, customer.customerId, shouldCreateNewCart]);

    return {
        error,
        loading: loading || creatingCart
    };
};
