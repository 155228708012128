import {BuyAgainQuery, BuyAgainQuery_pageableFrequentPurchases_frequentPurchases} from 'autogen/BuyAgainQuery';
import {updateQuery} from 'client/utils/apollo-utils';
import {IProductFragment} from 'autogen/IProductFragment';
import {captureSentryError} from 'client/services/sentry-service';
import {removeDuplicateProducts} from 'client/utils/product-helpers';

import ProductSwimlaneOptions from '../../swimlanes/product-swimlane-options';

export default class BuyAgainProductsOption extends ProductSwimlaneOptions<BuyAgainQuery, IProductFragment> {
    private readonly header: string;

    private readonly seeAllUrl: string | undefined;

    public constructor(header: string, seeAllUrl?: string) {
        super();
        this.header = header;
        this.seeAllUrl = seeAllUrl;
    }

    public getHeader = () => this.header;

    public getNextPage = (data: BuyAgainQuery): number | null | undefined => {
        return data?.pageableFrequentPurchases?.nextPage;
    };

    public getItems = (data: BuyAgainQuery): IProductFragment[] => {
        const nestedData: BuyAgainQuery_pageableFrequentPurchases_frequentPurchases[] =
            data?.pageableFrequentPurchases?.frequentPurchases || [];

        const products = [
            ...new Set(nestedData.map(({product}) => product).filter((product) => product !== null))
        ] as IProductFragment[];

        return removeDuplicateProducts(products);
    };

    public mergePageResults = updateQuery('pageableFrequentPurchases', 'frequentPurchases');

    public getSeeAllUrl = () => this.seeAllUrl || '/aisles-online/lists/frequent-purchases/buy-again';

    public onError = (error) =>
        captureSentryError({
            component: 'buy-again-swimlane',
            error,
            message: 'Error querying for buy again swimlane data'
        });
}
