import {FC, PropsWithChildren, forwardRef, useEffect} from 'react';

import useIntersect from 'client/hooks/use-intersect';

import {ElementProps} from '../../../types/html-element-props';
import {useClassnames} from '../../../hooks/use-classnames';

import styles from './intersect-wrapper.module.css';

const Wrapper = forwardRef<any, ElementProps<HTMLDivElement> & {fullWidth: boolean}>(({fullWidth, ...props}, ref) => {
    const classnames = useClassnames(styles, {
        fullWidth,
        wrapper: true
    });

    return <div {...props} className={classnames} ref={ref} />;
});

interface IIntersectWrapperProps {
    onVisible: () => void;
    fullWidth?: boolean;
}

const IntersectWrapper: FC<PropsWithChildren<PropsWithChildren<IIntersectWrapperProps>>> = (props) => {
    const [ref, entry] = useIntersect();
    const {onVisible, children, fullWidth = false} = props;

    useEffect(() => {
        if (entry.isIntersecting) {
            onVisible();
        }
    }, [entry.isIntersecting, onVisible]);

    return (
        <Wrapper data-testid={'wrapper'} fullWidth={fullWidth} ref={ref}>
            {children}
        </Wrapper>
    );
};

export default IntersectWrapper;
