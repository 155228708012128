import {FC, PropsWithChildren} from 'react';
import ProductCardSkeleton from '@hy-vee/web-ecommerce/lib/components/product-card-skeleton';

import {ElementProps} from '../../../../types/html-element-props';

import styles from './swimlane-skeleton.module.css';

const StyledSwimlaneContainer: FC<PropsWithChildren<PropsWithChildren<ElementProps<HTMLDivElement>>>> = (props) => (
    <div {...props} className={styles.styledSwimlaneContainer} />
);

const SwimlaneSkeleton: FC<PropsWithChildren<PropsWithChildren>> = () => {
    return (
        <StyledSwimlaneContainer data-testid={'product-details-swimlane-skeleton'}>
            <ProductCardSkeleton />
            <ProductCardSkeleton />
            <ProductCardSkeleton />
            <ProductCardSkeleton />
        </StyledSwimlaneContainer>
    );
};

export default SwimlaneSkeleton;
