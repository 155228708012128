import {captureSentryError} from 'client/services/sentry-service';
import {updateQuery} from 'client/utils/apollo-utils';

import {PopularProductsQuery} from '../../../../../autogen/PopularProductsQuery';
import {removeDuplicateProducts} from '../../../../utils/product-helpers';
import {IProductFragment} from '../../../../../autogen/IProductFragment';
import ProductSwimlaneOptions from '../../swimlanes/product-swimlane-options';

export default class PopularProductsOption extends ProductSwimlaneOptions<PopularProductsQuery, IProductFragment> {
    private readonly header: string;

    public constructor(header: string) {
        super();
        this.header = header;
    }

    public getHeader = (): string => this.header;

    public getNextPage = (data: PopularProductsQuery) => data?.pageablePopularProducts?.nextPage;

    public getItems = (data: PopularProductsQuery | undefined) =>
        data?.pageablePopularProducts?.products ? removeDuplicateProducts(data.pageablePopularProducts.products) : [];

    public mergePageResults = updateQuery('pageablePopularProducts', 'products');

    public getSeeAllUrl = (): undefined => undefined;

    public onError = (error) =>
        captureSentryError({
            component: 'popular-products-swimlane',
            error,
            message: 'Error querying for popular product swimlane data'
        });
}
