import {FC, PropsWithChildren} from 'react';
import {NextSeo} from 'next-seo';
import {OpenGraphMedia} from 'next-seo/lib/types';

import {ISEO} from './types';

export interface IServerRenderSEOProps {
    seo?: ISEO | null | undefined;
}

const ServerRenderSEO: FC<PropsWithChildren<PropsWithChildren<IServerRenderSEOProps>>> = ({seo}) => {
    if (!seo) {
        return null;
    }

    const images: OpenGraphMedia[] = [];

    if (seo?.pageImageUrl) {
        images.push({
            url: seo.pageImageUrl
        });
    }

    if (seo?.pageImage?.asset?.url) {
        images.push({
            url: seo.pageImage.asset.url
        });
    }

    const title = seo?.pageTitle;

    return (
        <NextSeo
            description={seo?.pageDescription}
            openGraph={{
                images,
                title
            }}
            title={title}
        />
    );
};

export default ServerRenderSEO;
