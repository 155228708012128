import {captureSentryError} from 'client/services/sentry-service';
import {GetRecommendationGroupsQuery} from 'autogen/GetRecommendationGroupsQuery';
import {shuffle} from 'client/utils/view-helpers/array-helpers';
import {removeDuplicateProducts} from 'client/utils/product-helpers';
import {IProdxProduct} from 'client/types/prodx';

import ProductSwimlaneOptions from '../../swimlanes/product-swimlane-options';

export default class RecommendationSwimlaneOptions extends ProductSwimlaneOptions<
    GetRecommendationGroupsQuery,
    IProdxProduct
> {
    public getHeader = (): string => {
        return 'You May Also Like';
    };

    public getNextPage = (): null => {
        return null;
    };

    public getItems = (data: GetRecommendationGroupsQuery | undefined): IProdxProduct[] => {
        if (!data?.recommendations?.groups) {
            return [];
        }

        const responseId = data.recommendations?.responseId;
        const prodxProducts: IProdxProduct[] = [];

        for (const recommendation of data.recommendations.groups) {
            if (recommendation.products) {
                for (const product of recommendation.products) {
                    const prodx: IProdxProduct = {
                        ...product.product,
                        responseId,
                        responseProductId: product.responseProductId
                    };

                    prodxProducts.push(prodx);
                }
            }
        }

        return shuffle(removeDuplicateProducts(prodxProducts));
    };

    public mergePageResults = (previousData: GetRecommendationGroupsQuery) => previousData;

    public getSeeAllUrl = () => '';

    public onError = (error) =>
        captureSentryError({
            component: 'recommendation-swimlane-options',
            error,
            message: 'Error querying for recommendation swimlanes data'
        });
}
