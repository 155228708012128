import styled from 'styled-components';
import {breakpoints, colors} from '@hy-vee/themes';

interface IStyledBackButtonWrapper {
    paddingLeft: string;
}

interface IStyledBackButtonContainer {
    isMobile: boolean;
}

export const StyledBackButtonContainer = styled.div`
    align-items: center;
    justify-content: center;
    display: ${(props: IStyledBackButtonContainer) => (props.isMobile ? 'none' : 'flex')};
    width: 100%;
    margin-top: 5px;

    @media only screen and (max-width: ${breakpoints.large}) {
        position: absolute;
        left: 16px;
        top: 18px;
    }
`;

export const StyledAbsoluteBackButtonContainer = styled.div`
    position: absolute;
    left: 0;
    top: 24px;
`;

export const StyledBackButtonWrapper = styled.div`
    padding-left: ${(props: IStyledBackButtonWrapper) => props.paddingLeft};
    @media only screen and (max-width: ${breakpoints.large}) {
        padding-left: 10px;
    }

    max-width: 1440px;
    width: 100%;
`;

export const ArrowBackIconContainer = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
    max-width: 70px;
`;

export const StyledBackButtonLabel = styled.label`
    color: ${colors.grey[500]};
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    word-wrap: break-word;
    cursor: pointer;
`;
