import {useCustomer, useCustomerData, useCustomerUuid} from 'client/hooks/customer-hooks';
import {getCustomerPredictionProductsQuery} from 'client/graphql/queries/product-queries';
import ProdxAislesType from 'client/enums/ProdxAislesType';
import {useCustomerDataToGetLocationId} from 'client/hooks/location-hooks';
import {EWIC_STORES, PERKS_PRICING} from 'client/enums/feature-toggle-names';
import {useFeatureToggle} from 'client/context/feature-toggle';
import {
    GetCustomerPredictionProductsQuery,
    GetCustomerPredictionProductsQueryVariables
} from 'autogen/GetCustomerPredictionProductsQuery';

import {useGraphqlSwimlane} from '../hooks/useGraphqlSwimlane';

import PredictionSwimlaneOptions from './prediction-swimlane-options';
import HomePageProdxSwimlane from './home-page-prodx-swimlane';

const swimlaneOptions = new PredictionSwimlaneOptions();

interface IPredictionSwimlane {
    lazyload: boolean;
}

const PredictionSwimlane = ({lazyload}: IPredictionSwimlane) => {
    const customerUuid = useCustomerUuid();
    const {pickupLocationHasLocker, storeId} = useCustomerData();
    const {fuelSaverCardUuid} = useCustomer();
    const {featureEnabled, featuresEnabled} = useFeatureToggle();
    const perksPricingEnabled = featureEnabled(PERKS_PRICING);
    const {locationId, loading: locationLoading} = useCustomerDataToGetLocationId();

    const wicEnabled = featuresEnabled([EWIC_STORES]);

    const {data, fetchMore, loading} = useGraphqlSwimlane<
        GetCustomerPredictionProductsQuery,
        GetCustomerPredictionProductsQueryVariables
    >(swimlaneOptions, getCustomerPredictionProductsQuery, {
        skip: !customerUuid || locationLoading,
        variables: {
            customerUuid,
            itemEnabled: true,
            locationIds: [locationId],
            perksPricingEnabled,
            pickupLocationHasLocker,
            storeId,
            targeted: Boolean(fuelSaverCardUuid),
            wicEnabled
        }
    });

    return (
        <HomePageProdxSwimlane
            aislesId={ProdxAislesType.PREDICTIONS}
            aria-label={swimlaneOptions.getHeader()}
            data={swimlaneOptions.getItems(data)}
            fetchMore={fetchMore}
            header={{
                seeAllUrl: swimlaneOptions.getSeeAllUrl(),
                text: swimlaneOptions.getHeader()
            }}
            lazyload={lazyload}
            loading={loading}
            swimlaneTestId={'predictions-swimlane'}
        />
    );
};

export default PredictionSwimlane;
