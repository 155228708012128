import getConfig from 'next/config';

import {useCustomer, useCustomerData} from 'client/hooks/customer-hooks';
import {getBuyAgainProducts} from 'client/graphql/queries/product-queries';
import {useCustomerDataToGetLocationId} from 'client/hooks/location-hooks';
import {EWIC_STORES, PERKS_PRICING} from 'client/enums/feature-toggle-names';
import {useFeatureToggle} from 'client/context/feature-toggle';
import ProdxAislesType from 'client/enums/ProdxAislesType';
import {GetBuyAgainProducts, GetBuyAgainProductsVariables} from 'autogen/GetBuyAgainProducts';

import {useHomePageContext} from '../home-page-provider';
import {BuyAgainProductsSortDirection} from '../../../../../autogen/globalTypes';
import {useGraphqlSwimlane} from '../hooks/useGraphqlSwimlane';

import HomePageProdxSwimlane from './home-page-prodx-swimlane';
import BuyAgainV2ProductsOption from './buy-again-v2-swimlane-options';

interface IBuyAgainSwimlaneProps {
    lazyload: boolean;
}

const BuyAgainSwimlane = ({lazyload}: IBuyAgainSwimlaneProps) => {
    const {customerId, storeId} = useCustomerData();
    const {fuelSaverCardUuid, customerUuid} = useCustomer();
    const {buyAgain} = useHomePageContext();
    const {featureEnabled, featuresEnabled} = useFeatureToggle();
    const perksPricingEnabled = featureEnabled(PERKS_PRICING);
    const {locationId, loading: locationLoading} = useCustomerDataToGetLocationId();
    const options = new BuyAgainV2ProductsOption('Buy Again');
    const aisleId = getConfig().publicRuntimeConfig.prodx.eventApi[ProdxAislesType.BUY_AGAIN_HOME_PAGE];
    const wicEnabled = featuresEnabled([EWIC_STORES]);

    const {data, loading, fetchMore} = useGraphqlSwimlane<GetBuyAgainProducts, GetBuyAgainProductsVariables>(
        options,
        getBuyAgainProducts,
        {
            skip: locationLoading || !customerId,
            variables: {
                input: {
                    aisleId,
                    customerUuid: customerUuid.toLowerCase(),
                    sortDirection: BuyAgainProductsSortDirection.FREQUENT,
                    storeId
                },
                itemEnabled: true,
                locationIds: [locationId],
                perksPricingEnabled,
                pickupLocationHasLocker: false,
                retailItemEnabled: Boolean(locationId),
                storeId,
                targeted: Boolean(fuelSaverCardUuid),
                wicEnabled
            }
        }
    );

    const swimlaneData = options.getItems(data);

    return (
        <HomePageProdxSwimlane
            aislesId={ProdxAislesType.BUY_AGAIN_HOME_PAGE}
            aria-label={options.getHeader()}
            data={swimlaneData}
            fetchMore={fetchMore}
            header={{
                seeAllUrl: options.getSeeAllUrl(),
                text: options.getHeader()
            }}
            lazyload={lazyload}
            loading={locationLoading || loading}
            notifyVisibility={buyAgain.setVisibility}
            swimlaneTestId={'buy-again-swimlane'}
        />
    );
};

export default BuyAgainSwimlane;
