import {FC, PropsWithChildren} from 'react';

import AislesOnlineLink from '../../aisles-online-link';
import ProdxProductSwimlane, {IProdxProductSwimlaneProps} from '../../swimlanes/prodx-product-swimlane';

import {StyledHeader, SwimLaneHeader, SwimlaneWrapper} from './home-page-swimlane-styles';

const Header: FC<PropsWithChildren<PropsWithChildren<{text: string; seeAllUrl: string}>>> = ({text, seeAllUrl}) => {
    return (
        <SwimLaneHeader>
            <StyledHeader as="h2" tabIndex={0}>
                {text}
            </StyledHeader>
            <AislesOnlineLink linkUrl={seeAllUrl} testId="swimlane-see-all">
                {'See All'}
            </AislesOnlineLink>
        </SwimLaneHeader>
    );
};

interface IHomePageProdxSwimlaneProps extends IProdxProductSwimlaneProps {
    id?: string;
    header: {
        text: string;
        seeAllUrl: string;
    };
}

const HomePageProdxSwimlane: FC<PropsWithChildren<PropsWithChildren<IHomePageProdxSwimlaneProps>>> = ({
    header,
    ...props
}) => {
    return (
        <SwimlaneWrapper id={props.id}>
            <ProdxProductSwimlane {...props} HeaderComponent={<Header {...header} />} />
        </SwimlaneWrapper>
    );
};

export default HomePageProdxSwimlane;
