import {FC, PropsWithChildren} from 'react';
import NextLink from 'next/link';
import {Link} from '@hy-vee/design-system';

import styles from './aisles-online-link.module.css';
import AislesOnlineLinkTypes from './aisles-online-link-types';

interface IAislesOnlineLinkProps {
    alwaysVisible?: boolean;
    classLink?: AislesOnlineLinkTypes;
    linkUrl?: string | null;
    overrideLinkStyles?: boolean;
    style?: object;
    testId?: string;
    handleOnPromoClick?: () => void;
}

const AislesOnlineLink: FC<PropsWithChildren<PropsWithChildren<IAislesOnlineLinkProps>>> = ({
    children,
    classLink,
    linkUrl,
    alwaysVisible = false,
    overrideLinkStyles = false,
    style,
    testId,
    handleOnPromoClick
}) => {
    if (!linkUrl && !alwaysVisible) {
        return null;
    }

    const linkClassName = (classLink && styles[classLink]) ?? undefined;

    let link = overrideLinkStyles ? (
        <a
            className={linkClassName}
            data-testid={testId || 'link-button'}
            href={linkUrl || undefined}
            onClick={handleOnPromoClick}
            style={style}
        >
            {children}
        </a>
    ) : (
        <Link data-testid={testId || 'link-button'} href={linkUrl || ''} onClick={handleOnPromoClick} style={style}>
            {children}
        </Link>
    );

    const stringUrl = linkUrl || '';
    const [, rootLevelPath] = stringUrl.split('/');

    link =
        rootLevelPath === 'aisles-online' ? (
            <NextLink href={stringUrl.replace(/^(\/aisles-online)/u, '')} legacyBehavior onClick={handleOnPromoClick}>
                {link}
            </NextLink>
        ) : (
            link
        );

    return link;
};

export default AislesOnlineLink;
