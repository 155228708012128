import {FC, createContext, useState, useContext, PropsWithChildren} from 'react';

export interface IHomePageContext {
    buyAgain: {
        isVisible: boolean;
        setVisibility: (boolean) => void;
    };
}

const HomePageContext = createContext<IHomePageContext | undefined>(undefined);

export const useHomePageContext = (): IHomePageContext => {
    const context = useContext(HomePageContext);

    if (!context) {
        throw new Error(
            'Unable to create home page context, please ensure your component is within the HomePageProvider'
        );
    }

    return context;
};

const setVisibilityWrapper = (update, current, set) => {
    if (update !== current) {
        set(update);
    }
};

const HomePageProvider: FC<PropsWithChildren<PropsWithChildren>> = ({children}) => {
    const [isBuyAgainVisible, setIsBuyAgainVisible] = useState(true);

    const value: IHomePageContext = {
        buyAgain: {
            isVisible: isBuyAgainVisible,
            setVisibility: (update) => setVisibilityWrapper(update, isBuyAgainVisible, setIsBuyAgainVisible)
        }
    };

    return <HomePageContext.Provider value={value}>{children}</HomePageContext.Provider>;
};

export default HomePageProvider;
