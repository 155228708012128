import {captureSentryError} from 'client/services/sentry-service';
import {GetCustomerPredictionProductsQuery} from 'autogen/GetCustomerPredictionProductsQuery';
import {IProdxProduct} from 'client/types/prodx';

import {removeDuplicateProducts} from '../../../../utils/product-helpers';
import ProductSwimlaneOptions from '../../swimlanes/product-swimlane-options';

export default class PredictionSwimlaneOptions extends ProductSwimlaneOptions<
    GetCustomerPredictionProductsQuery,
    IProdxProduct
> {
    public getHeader = (): string => {
        return 'Need to Restock?';
    };

    public getNextPage = (): null => {
        return null;
    };

    public getItems = (data: GetCustomerPredictionProductsQuery | undefined): IProdxProduct[] => {
        if (!data?.predictionProducts?.groups) {
            return [];
        }

        const responseId = data?.predictionProducts?.responseId;

        const prodxProducts: IProdxProduct[] = [];

        for (const prediction of data?.predictionProducts.groups) {
            if (prediction.products) {
                for (const product of prediction.products) {
                    const prodx: IProdxProduct = {
                        ...product.product,
                        responseId,
                        responseProductId: product.responseProductId
                    };

                    prodxProducts.push(prodx);
                }
            }
        }

        return removeDuplicateProducts(prodxProducts);
    };

    public mergePageResults = (previousData: GetCustomerPredictionProductsQuery) => previousData;

    public getSeeAllUrl = () => '';

    public onError = (error) =>
        captureSentryError({
            component: 'prediction-swimlane-options',
            error,
            message: 'Error querying for prediction swimlanes data'
        });
}
