import {gql} from '@apollo/client';

const fuelSaverCardFragment = gql`
    fragment IFuelSaverCard on fuelSaverCard {
        __typename
        fuelSaverCardUuid
        customerUuid
        fuelSaverCardNumber
        accounts {
            accountName
            accrualAmount
            accrualStartDate
            rewardBalance
            rewards {
                expirationDate
                earnedDate
                redeemedDate
                rewardValue
                status
            }
        }
    }
`;

export const getFuelSaverBalance = gql`
    ${fuelSaverCardFragment}

    query GetCustomerFuelSaverQuery($customerUuid: String!) {
        customer(customerUuid: $customerUuid) {
            customerId
            customerUuid
            fuelSaverCard {
                ...IFuelSaverCard
            }
        }
    }
`;
