import {createMedia} from '@artsy/fresnel';

const AppMedia = createMedia({
    breakpoints: {
        desktop: 600,
        mobile: 0
    }
});

export const mediaStyles = AppMedia.createMediaStyle();

export const {Media, MediaContextProvider} = AppMedia;
