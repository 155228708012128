import {FC, PropsWithChildren, useState} from 'react';
import styled from 'styled-components';
import {hyvee, colors, spacing} from '@hy-vee/themes';
import {LinkButton} from '@hy-vee/web-core/lib/components/button';
import {IDType} from '@hy-vee/ts-utils';

import {useCustomer, useIsAuthenticated} from '../../../hooks/customer-hooks';
import {ICouponV4} from '../../../../autogen/ICouponV4';
import {AO_MAX_MOBILE_WIDTH} from '../../../styles/style-constants';

import ProductCouponModal from './product-coupon-modal';
import CouponCardButton from './coupon-card-button';

const Wrapper = styled.div`
    margin: 0 var(--spacing--x-lg) var(--spacing--x-lg) 0;
    background-color: white;
    border-radius: 8px;
`;

const TextAndImageWrapper = styled.div`
    :before {
        content: '';
        position: absolute;
        border: 5px dashed ${colors.grey[300]};
        top: -3px;
        bottom: -3px;
        left: -3px;
        right: -3px;
    }
    background-color: white;
    overflow: hidden;
    position: relative;
    text-align: center;
    padding-bottom: 24px;
    padding-left: 24px;
    margin-right: var(--spacing--sm);
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 450px;
    height: 300px;

    @media screen and (max-width: ${AO_MAX_MOBILE_WIDTH}px) {
        align-items: center;
        flex-direction: column;
        width: 250px;
        height: auto;
        padding-left: 0;
        padding-bottom: 0;
    }
`;

const StyledValueText = styled.div`
    color: ${hyvee.primary};
    font-size: 32px;
    font-weight: 400;
`;

const StyledDescriptionWrapper = styled.div`
    max-height: 72px;
    position: relative;
    overflow: hidden;
    margin: 12px 0 0 0;
    :before {
        font-size: 20px;
        position: absolute;
        content: '...';
        inset-block-end: -4px; /* "bottom" */
        inset-inline-end: -1px; /* "right" */
    }

    @media screen and (max-width: ${AO_MAX_MOBILE_WIDTH}px) {
        margin: 12px 0 0 8px;
    }
`;

const StyledDescriptionText = styled.div`
    position: relative;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: ${colors.grey[500]};
    width: 95%;
    :after {
        content: '';
        position: absolute;
        inset-inline-end: -17px; /* "right" */
        width: 24px;
        height: 24px;
        background: white;
    }
`;

const StyledCouponImage = styled.img`
    display: flex;
    height: 125px;
    margin: 24px 12px 24px 12px;
    width: 125px;
`;

const StyledTextAndButtonWrapper = styled.div`
    align-items: center;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    height: 300px;
    padding: 12px;
`;

const StyledTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const StyledLoadButtonWrapper = styled.div`
    display: flex;
    width: 100%;
`;

const StyledEndDateText = styled.div`
    font-size: 16px;
    color: ${colors.grey[400]};
    font-weight: 400;
    padding-right: ${spacing.small};

    @media screen and (max-width: ${AO_MAX_MOBILE_WIDTH}px) {
        line-height: 19px;
    }
`;

const StyledEndDateAndDetailsWrapper = styled.div`
    display: flex;
    padding-right: ${spacing.small};
    flex-direction: row;
    justify-content: center;

    @media screen and (max-width: ${AO_MAX_MOBILE_WIDTH}px) {
        align-items: center;
        padding-right: 0px;
        padding-top: ${spacing.small};
    }
`;

const SwimlaneCouponCard: FC<PropsWithChildren<PropsWithChildren<{coupon: ICouponV4; index: number}>>> = ({
    coupon,
    index
}) => {
    const isAuthenticated = useIsAuthenticated();
    const {fuelSaverCardUuid} = useCustomer();
    const [modalCouponId, setCouponModalId] = useState<IDType | null>(null);

    const detailsOnClick = () => setCouponModalId(coupon.couponId);

    return (
        <Wrapper aria-posinset={index} aria-setsize={-1} role="article">
            <TextAndImageWrapper>
                <StyledCouponImage alt="" src={coupon.imageUrl} />
                <StyledTextAndButtonWrapper>
                    <StyledTextWrapper>
                        <StyledValueText>{coupon.valueText}</StyledValueText>
                        <StyledDescriptionWrapper>
                            <StyledDescriptionText>{coupon.description}</StyledDescriptionText>
                        </StyledDescriptionWrapper>
                        <StyledEndDateAndDetailsWrapper>
                            <StyledEndDateText>{`END DATE ${coupon.clipEndDate}`}</StyledEndDateText>
                            <LinkButton onClick={detailsOnClick}>{'Details'}</LinkButton>
                        </StyledEndDateAndDetailsWrapper>
                    </StyledTextWrapper>
                    <StyledLoadButtonWrapper>
                        <CouponCardButton
                            couponId={coupon.couponId}
                            fuelSaverCardUuid={fuelSaverCardUuid}
                            isAuthenticated={isAuthenticated}
                        />
                    </StyledLoadButtonWrapper>
                </StyledTextAndButtonWrapper>
                <ProductCouponModal couponId={modalCouponId} setCouponModalId={setCouponModalId} />
            </TextAndImageWrapper>
        </Wrapper>
    );
};

export default SwimlaneCouponCard;
