import {captureSentryError} from 'client/services/sentry-service';
import {removeDuplicateProducts} from 'client/utils/product-helpers';
import {IProdxProduct} from 'client/types/prodx';

import ProductSwimlaneOptions from '../../swimlanes/product-swimlane-options';
import {GetBuyAgainProducts} from '../../../../../autogen/GetBuyAgainProducts';

export default class BuyAgainV2ProductsOption extends ProductSwimlaneOptions<GetBuyAgainProducts, IProdxProduct> {
    private readonly header: string;

    private readonly seeAllUrl: string | undefined;

    public constructor(header: string, seeAllUrl?: string) {
        super();
        this.header = header;
        this.seeAllUrl = seeAllUrl;
    }

    public getHeader = () => this.header;

    public getNextPage = (data: GetBuyAgainProducts): number | null | undefined => {
        return data?.buyAgainProducts?.nextPage;
    };

    public getItems = (data: GetBuyAgainProducts | undefined): IProdxProduct[] => {
        const nestedData = data?.buyAgainProducts?.products || [];

        const products = nestedData
            .filter((product) => product?.product)
            .map((product) => ({
                ...product?.product,
                responseId: data?.buyAgainProducts?.responseId,
                responseProductId: product?.responseProductId,
                shelfProductId: product?.shelfProductId
            }));

        return removeDuplicateProducts(products);
    };

    public mergePageResults = (
        previousData: GetBuyAgainProducts,
        {fetchMoreResult}: {fetchMoreResult: GetBuyAgainProducts}
    ): GetBuyAgainProducts => {
        const previousValues = previousData?.buyAgainProducts?.products || [];
        const newValues = fetchMoreResult?.buyAgainProducts?.products || [];

        const updated = [...previousValues, ...newValues];

        return {
            ...fetchMoreResult,
            buyAgainProducts: {
                __typename: 'BuyAgainProductsResult',
                nextPage: fetchMoreResult?.buyAgainProducts?.nextPage || null,
                page: fetchMoreResult?.buyAgainProducts?.page || 1,
                productGroups: null,
                products: updated,
                responseId: fetchMoreResult?.buyAgainProducts?.responseId || '',
                totalCount: fetchMoreResult?.buyAgainProducts?.totalCount || 0
            }
        };
    };

    public getSeeAllUrl = () => this.seeAllUrl || '/aisles-online/lists/frequent-purchases/buy-again';

    public onError = (error) =>
        captureSentryError({
            component: 'buy-again-swimlane',
            error,
            message: 'Error querying for buy again swimlane data'
        });
}
