import {getBlockContentSerializer, WebComponents} from '@hy-vee/react-web-and-mobile-ui-components';
import BlockContent from '@sanity/block-content-to-react';
import {useQuery} from '@apollo/client';
import styled from 'styled-components';

import CategoryPageLoading from '../../client/views/components/category/category-page-loading';
import PageWithCustomerDataWrapper from '../../client/views/components/authentication/page-with-customer-data-wrapper';
import SanityCollectionSwimlane from '../../client/views/components/brand/sanity-collection-swimlane';
import {withDynamicStatic} from '../../client/utils/with-dynamic-static';
import {IBrandLandingPageProps} from '../../client/context/brand-landing/types';
import {
    getBrandLandingPage as GetBrandLandingPage,
    getBrandLandingPageVariables
} from '../../autogen/getBrandLandingPage';
import {getBrandLandingPage} from '../../client/graphql/queries/brand-queries';
import {TWO_LEGGED_AUTH_TYPE} from '../../client/enums/auth-types';
import IApolloQuery from '../../client/types/apollo-query';
import {useCustomer, useIsAuthenticated} from '../../client/hooks/customer-hooks';
import {env} from '../../client/utils/environment';
import {useInViewEvent} from 'client/hooks/use-in-view-event';
import {reportEvolyticsImpression} from 'client/services/analytics-service';
import GTMEvent from 'client/enums/gtm-event';

const LandingPageContainer = styled.div<{backgroundColor?: {hex: string}}>`
    display: flex;
    flex: 1;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
    background-color: ${(props) => (props.backgroundColor ? props.backgroundColor.hex : 'transparent')};
`;

const BrandLandingPage = (brandLandingPageProps: IBrandLandingPageProps) => {
    const {serverRenderSeo, slug} = brandLandingPageProps;

    const customer = useCustomer();

    const {data, loading} = useQuery<GetBrandLandingPage, IApolloQuery<getBrandLandingPageVariables>>(
        getBrandLandingPage,
        {
            variables: {
                authType: TWO_LEGGED_AUTH_TYPE,
                slugFilter: {
                    current: {
                        eq: slug ? slug : ''
                    }
                }
            }
        }
    );

    const getTacticId = (brandLandingPagedata): string | undefined => {
        return brandLandingPagedata?.allCmsSharedBrandLandingPage[0]?.body[0]?.tacticId;
    };

    const ad = {
        category: GTMEvent.BrandLandingPage,
        tacticId: getTacticId(data),
        title: slug
    };

    const {ref} = useInViewEvent({triggerOnce: true}, (elementPosition) =>
        reportEvolyticsImpression(
            {
                ...ad,
                ...elementPosition
            },
            customer,
            GTMEvent.AdPageLoad
        )
    );

    const contextProps = {
        callbacks: {},
        userFlags: {
            customerId: Number(customer?.customerId),
            customerUuid: customer?.customerUuid,
            environment: env(),
            fuelSaverCardNumber: customer?.fuelSaverCard?.fuelSaverCardNumber,
            isAuthenticated: useIsAuthenticated()
        }
    };

    const serializers = {
        ...getBlockContentSerializer(WebComponents, {}),
        types: {
            ...getBlockContentSerializer(WebComponents, contextProps).types,

            cmsProductCollection: (props) => (
                <LandingPageContainer backgroundColor={props.node.backgroundColor}>
                    <SanityCollectionSwimlane
                        collectionId={props.node.collectionId}
                        lazyload
                        promo={props.node.promo}
                    />
                </LandingPageContainer>
            )
        }
    };

    if (loading) {
        return <CategoryPageLoading />;
    }

    return (
        <div data-testid="analytics-dispatcher" ref={ref}>
            <PageWithCustomerDataWrapper LoadingComponent={<CategoryPageLoading />} title={serverRenderSeo?.pageTitle}>
                <BlockContent
                    blocks={data?.allCmsSharedBrandLandingPage[0].body}
                    className="brand-landing-page-serializer"
                    data-testid="block-content-serializer"
                    serializers={serializers}
                />
            </PageWithCustomerDataWrapper>
        </div>
    );
};

export default withDynamicStatic(BrandLandingPage, CategoryPageLoading);

export {
    brandLandingStaticPaths as getStaticPaths,
    brandLandingStaticProps as getStaticProps
} from '../../ssg/brand-landing';
