import {FC, PropsWithChildren} from 'react';
import styled, {css} from 'styled-components';
import ProductCardSkeleton from '@hy-vee/web-ecommerce/lib/components/product-card-skeleton';

import {AO_MAX_SWIMLANE_WIDTH, AO_MAX_MOBILE_WIDTH} from 'client/styles/style-constants';

export const SwimLaneContainer = styled.div`
    max-width: 100%;
    -ms-overflow-style: -ms-autohiding-scrollbar;
`;

export const StyledProductCardsContainer = styled.div`
    display: flex;
    height: 100%;
    gap: var(--spacing--lg);
    overflow: hidden;
    position: relative;
    flex-direction: column;

    @media (min-width: 48rem) {
        gap: 40px;
    }
`;

export const ItemsContainer = styled.div`
    display: flex;
    gap: var(--spacing--md);
`;

export const StyledDrawer = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: row;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    scroll-padding: 100px;

    scrollbar-width: none;

    :hover {
        scrollbar-width: auto;
    }

    :hover::-webkit-scrollbar {
        display: contents;
        background-color: var(--color-white);
    }

    :hover::-webkit-scrollbar-thumb {
        background-color: lightgray;
        border-radius: 20px;
        ${css`
            border: 3px solid var(--color-gray--10);
        `}
        :hover {
            background-color: gray;
        }
    }

    ::-webkit-scrollbar {
        display: none;
    }

    @media only screen and (max-width: ${AO_MAX_SWIMLANE_WIDTH}px) {
        margin: 0;
    }

    @media (max-width: ${AO_MAX_MOBILE_WIDTH}px) {
        :hover::-webkit-scrollbar {
            display: none;
        }
    }
`;

export const SwimlaneLoading: FC<
    PropsWithChildren<
        PropsWithChildren<{
            count: number;
            LoadingComponent?: FC<PropsWithChildren<PropsWithChildren>>;
            className?: string;
        }>
    >
> = ({count, LoadingComponent, className}) => {
    const Component = LoadingComponent ? LoadingComponent : ProductCardSkeleton;
    const loadingProps = !LoadingComponent
        ? {
              height: '435px'
          }
        : {
              height: '300px',
              width: '450px'
          };

    return (
        <div className={className} data-testid={'swimlane-loading'}>
            <StyledProductCardsContainer>
                <StyledProductCardsContainer>
                    <StyledDrawer>
                        {[...new Array(count).keys()].map((val) => (
                            <Component key={val} {...loadingProps} />
                        ))}
                    </StyledDrawer>
                </StyledProductCardsContainer>
            </StyledProductCardsContainer>
        </div>
    );
};
