export enum ProdxAislesType {
    BROWSE = 'browseAislesId',
    BUY_AGAIN = 'buyAgainAisleId',
    BUY_AGAIN_ADD_TO_ORDER = 'buyAgainAddToOrderAisleId',
    BUY_AGAIN_HOME_PAGE = 'buyAgainHomePageAisleId',
    CATEGORY = 'categoryAislesId',
    COMPLEMENTS_DETAIL_PAGE = 'complementsDetailPageAisleId',
    COMPLEMENTS_PRODUCTS = 'complementsProductsAisleId',
    LIST_RECOMMENDATIONS = 'listsRecommendationsV2AisleId',
    PREDICTIONS = 'predictionsAislesId',
    RECOMMENDATIONS = 'recommendationsAislesId',
    SEARCH = 'searchWebAisleId',
    SEARCH_RECOMMENDATIONS_PRODUCTS = 'recommendationsV2SearchWebAisleId',
    SUBSTITUTIONS = 'substitutionsAisleId'
}

export default ProdxAislesType;
