import {FC, PropsWithChildren} from 'react';

import IApolloQuery from 'client/types/apollo-query';

import {getCouponsForCouponSwimlane} from '../../../../graphql/queries/coupon-queries';
import {THREE_LEGGED_AUTH_TYPE, TWO_LEGGED_AUTH_TYPE} from '../../../../enums/auth-types';
import SwimlaneCouponCard from '../../coupons/swimlane-coupon-card';
import CouponCardSkeleton from '../../coupons/coupon-card-skeleton';
import {useCustomer, useIsAuthenticated} from '../../../../hooks/customer-hooks';
import Swimlane from '../../swimlanes/swimlane';
import AislesOnlineLink from '../../aisles-online-link';
import {useGraphqlSwimlane} from '../hooks/useGraphqlSwimlane';
import {
    GetCouponsForCouponSwimlane,
    GetCouponsForCouponSwimlaneVariables
} from '../../../../../autogen/GetCouponsForCouponSwimlane';

import CouponSwimlaneOptions from './coupon-swimlane-options';
import {StyledHeader, SwimLaneHeader, SwimlaneWrapper} from './home-page-swimlane-styles';

const HeaderComponent: FC<PropsWithChildren<PropsWithChildren<{text: string; seeAllUrl: string}>>> = ({
    text,
    seeAllUrl
}) => {
    return (
        <SwimLaneHeader>
            <StyledHeader as="h2" tabIndex={0}>
                {text}
            </StyledHeader>
            <AislesOnlineLink linkUrl={seeAllUrl} testId="swimlane-see-all">
                {'See All'}
            </AislesOnlineLink>
        </SwimLaneHeader>
    );
};

const CouponSwimlane = () => {
    const isAuthenticated = useIsAuthenticated();
    const {fuelSaverCardUuid} = useCustomer();
    const header = 'Digital Coupons';

    const option = new CouponSwimlaneOptions(header);

    const childComponentRenderer = (coupon, index) => (
        <SwimlaneCouponCard coupon={coupon} index={index} key={coupon.couponId} />
    );

    const {data, loading, fetchMore} = useGraphqlSwimlane<
        GetCouponsForCouponSwimlane,
        IApolloQuery<GetCouponsForCouponSwimlaneVariables>
    >(option, getCouponsForCouponSwimlane, {
        variables: {
            authType: isAuthenticated ? THREE_LEGGED_AUTH_TYPE : TWO_LEGGED_AUTH_TYPE,
            filters: {
                index: 0,
                size: 15
            },
            fuelSaverCardUuid
        }
    });

    return (
        <SwimlaneWrapper>
            <Swimlane
                HeaderComponent={<HeaderComponent seeAllUrl={option.getSeeAllUrl()} text={option.getHeader()} />}
                LoadingComponent={CouponCardSkeleton}
                aria-label={option.getHeader()}
                childComponentRenderer={childComponentRenderer}
                data={option.getItems(data)}
                fetchMore={fetchMore}
                lazyload
                loading={loading}
                swimlaneTestId={'coupon-swimlane'}
            />
        </SwimlaneWrapper>
    );
};

export default CouponSwimlane;
