import {FC, PropsWithChildren} from 'react';

import {popularProductsQuery} from 'client/graphql/queries/product-queries';
import {useCustomer, useCustomerData} from 'client/hooks/customer-hooks';
import {THREE_LEGGED_AUTH_TYPE, TWO_LEGGED_AUTH_TYPE} from 'client/enums/auth-types';
import {useCustomerDataToGetLocationId} from 'client/hooks/location-hooks';
import {EWIC_STORES, PRICING_ITEM_API} from 'client/enums/feature-toggle-names';
import {useFeatureToggle} from 'client/context/feature-toggle';
import IApolloQuery from 'client/types/apollo-query';
import {PopularProductsQuery, PopularProductsQueryVariables} from 'autogen/PopularProductsQuery';

import {useGraphqlSwimlane} from '../hooks/useGraphqlSwimlane';

import PopularProductsOption from './popular-products-swimlane-options';
import HomePageSwimlane from './home-page-swimlane';

const options = new PopularProductsOption('Popular Products');

interface IPopularProductsSwimlane {
    lazyload: boolean;
}

const PopularProductsSwimlane: FC<PropsWithChildren<PropsWithChildren<IPopularProductsSwimlane>>> = ({
    lazyload
}: IPopularProductsSwimlane) => {
    const {pickupLocationHasLocker, storeId} = useCustomerData();
    const {fuelSaverCardUuid} = useCustomer();
    const {featureEnabled, featuresEnabled} = useFeatureToggle();
    const pricingItemApiToggle = featureEnabled(PRICING_ITEM_API);
    const {locationId, loading: locationLoading} = useCustomerDataToGetLocationId();

    const wicEnabled = featuresEnabled([EWIC_STORES]);

    const {data, loading, fetchMore} = useGraphqlSwimlane<
        PopularProductsQuery,
        IApolloQuery<PopularProductsQueryVariables>
    >(options, popularProductsQuery, {
        skip: pricingItemApiToggle && locationLoading,
        variables: {
            authType: fuelSaverCardUuid ? THREE_LEGGED_AUTH_TYPE : TWO_LEGGED_AUTH_TYPE,
            itemEnabled: pricingItemApiToggle,
            locationIds: [locationId],
            pageSize: 10,
            pickupLocationHasLocker,
            retailItemEnabled: Boolean(locationId),
            storeId,
            targeted: Boolean(fuelSaverCardUuid),
            wicEnabled
        }
    });

    return (
        <HomePageSwimlane
            aria-label={options.getHeader()}
            data={options.getItems(data)}
            fetchMore={fetchMore}
            header={{
                seeAllUrl: options.getSeeAllUrl(),
                text: options.getHeader()
            }}
            lazyload={lazyload}
            loading={loading}
            swimlaneTestId={'popular-products-swimlane'}
        />
    );
};

export default PopularProductsSwimlane;
