import {FC, PropsWithChildren} from 'react';
import styled from 'styled-components';
import {hyveeRedOutline, green} from '@hy-vee/themes';
import {OutlineButton} from '@hy-vee/web-core';
import {CheckIcon} from '@hy-vee/icons';
import {useQuery} from '@apollo/client';

import {assignParentUrl} from '../../../utils/iframe-helpers';
import {FUEL_SAVER_PATH} from '../../../enums/rerouting-paths';
import {createClippedCouponV4} from '../../../graphql/mutations/mutation-actions/coupon-actions';
import {redirectToLogin} from '../../../utils/redirect-to-login';
import {getCouponV4ByCouponIdQuery} from '../../../graphql/queries/coupon-queries';
import {useAislesOnlineRouter} from '../../../hooks/use-aisles-online-router';

const StyledOutlineButton = styled(OutlineButton)`
    display: flex;
    padding: 12px 0;
    width: 100%;
`;

const StyledLoadedText = styled.div`
    color: ${green.mediumGreen};
    font-weight: 400;
    padding-left: 4px;
`;

const IconAndTextWrapper = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;
`;

interface ICouponCardButton {
    couponId: string;
    isAuthenticated: boolean;
    fuelSaverCardUuid?: string;
}

const CouponCardButton: FC<PropsWithChildren<PropsWithChildren<ICouponCardButton>>> = ({
    couponId,
    isAuthenticated,
    fuelSaverCardUuid
}) => {
    const {data} = useQuery(getCouponV4ByCouponIdQuery, {
        fetchPolicy: 'cache-only',
        variables: {
            couponId
        }
    });

    const router = useAislesOnlineRouter();

    if (!data) {
        return <StyledOutlineButton isLoading />;
    }

    if (!isAuthenticated) {
        return (
            <StyledOutlineButton onClick={() => redirectToLogin(router.asPathWithBasePath)} theme={hyveeRedOutline}>
                {'Log In to Load Coupon'}
            </StyledOutlineButton>
        );
    } else if (!fuelSaverCardUuid) {
        return (
            <StyledOutlineButton onClick={() => assignParentUrl(FUEL_SAVER_PATH)} theme={hyveeRedOutline}>
                {'Join Fuel Saver to Load'}
            </StyledOutlineButton>
        );
    }

    if (!data.couponV4.clipped) {
        return (
            <StyledOutlineButton
                data-testid="load-coupon-coupon-card"
                onClick={async () => createClippedCouponV4(fuelSaverCardUuid, couponId)}
            >
                {'Load Coupon'}
            </StyledOutlineButton>
        );
    }

    return (
        <IconAndTextWrapper>
            <CheckIcon color={green.mediumGreen} size="small" />
            <StyledLoadedText color={green.mediumGreen}>{'Coupon Loaded!'}</StyledLoadedText>
        </IconAndTextWrapper>
    );
};

export default CouponCardButton;
