import Case from 'case';

import {captureSentryError} from 'client/services/sentry-service';
import {
    getStoreProductsForSwimlane,
    getStoreProductsForSwimlane_storeProducts_storeProducts
} from 'autogen/getStoreProductsForSwimlane';
import {IProductFragment} from 'autogen/IProductFragment';
import {updateQuery} from 'client/utils/apollo-utils';
import {removeDuplicateProducts} from 'client/utils/product-helpers';

import ProductSwimlaneOptions from '../../swimlanes/product-swimlane-options';

export default class StoreProductSwimlaneOptions extends ProductSwimlaneOptions<
    getStoreProductsForSwimlane,
    IProductFragment
> {
    private readonly header: string;

    private readonly seeAllUrl: string | undefined;

    public constructor(header: string, seeAllUrl: string | undefined) {
        super();
        this.header = header;
        this.seeAllUrl = seeAllUrl;
    }

    public getHeader = () => this.header;

    public getNextPage = (data: getStoreProductsForSwimlane): number | null | undefined => {
        return data?.storeProducts?.nextPage;
    };

    public getItems = (data: getStoreProductsForSwimlane): IProductFragment[] => {
        const nestedData: getStoreProductsForSwimlane_storeProducts_storeProducts[] =
            data?.storeProducts?.storeProducts || [];

        const products = [
            ...new Set(nestedData.map(({product}) => product).filter((product) => product !== null))
        ] as IProductFragment[];

        return removeDuplicateProducts(products);
    };

    public mergePageResults = updateQuery('storeProducts', 'storeProducts');

    public getSeeAllUrl = () => this.seeAllUrl;

    public onError = (error) =>
        captureSentryError({
            component: `${Case.kebab(this.header)}-swimlane`,
            error,
            message: `Error querying for ${this.header} swimlane data`
        });
}
