import {FC, PropsWithChildren} from 'react';
import {ArrowBackIcon} from '@hy-vee/icons';

import {
    ArrowBackIconContainer,
    StyledBackButtonContainer,
    StyledBackButtonLabel,
    StyledBackButtonWrapper
} from './back-button-styles';

interface IBackButtonProps {
    onClick: () => void;
    size: string;
    buttonLabel?: string;
    paddingLeft?: string;
    isMobile?: boolean;
}

const BackButton: FC<PropsWithChildren<IBackButtonProps>> = (props) => {
    const {onClick, size, buttonLabel, paddingLeft = '0px', isMobile = false} = props;

    return (
        <StyledBackButtonContainer data-testid="back-btn-component" isMobile={isMobile}>
            <StyledBackButtonWrapper paddingLeft={paddingLeft}>
                <ArrowBackIconContainer data-testid="back-arrow-click" onClick={onClick}>
                    <ArrowBackIcon size={size} />
                    <StyledBackButtonLabel>{buttonLabel}</StyledBackButtonLabel>
                </ArrowBackIconContainer>
            </StyledBackButtonWrapper>
        </StyledBackButtonContainer>
    );
};

export default BackButton;
