import {Subnav, SubnavItem} from '@hy-vee/design-system';
import {useRouter} from 'next/router';

import {useSubNav} from './hooks/use-sub-nav';

const SubNavigation = () => {
    const router = useRouter();
    const {data, loading} = useSubNav();

    if (loading || !data) {
        return null;
    }

    if (data?.overrideUrls?.some((url) => url && router.asPath.includes(url))) {
        return null;
    }

    const items = data?.links?.map((link) => ({
        label: link?.text,
        url: link?.href
    })) as SubnavItem[];

    return <Subnav aria-label="Aisles Online Sub Navigation" branding="" items={items} />;
};

export default SubNavigation;
