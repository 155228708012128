import {FC, PropsWithChildren} from 'react';

import {ElementProps} from '../../../../types/html-element-props';

import {ProductDetailItemSkeleton} from './product-details-items-skeleton';
import styles from './product-details-information-skeleton.module.css';

const StyledProductDetailsInformation: FC<PropsWithChildren<PropsWithChildren<ElementProps<HTMLDivElement>>>> = (
    props
) => <div {...props} className={styles.styledProductDetailsInformation} />;

const StyledMobileInfoContainer: FC<PropsWithChildren<PropsWithChildren<ElementProps<HTMLDivElement>>>> = (props) => (
    <div {...props} className={styles.styledMobileInfoContainer} />
);

const MobileProductDetailsInformationSkeleton: FC<PropsWithChildren<PropsWithChildren>> = () => {
    return (
        <StyledMobileInfoContainer>
            <ProductDetailItemSkeleton size={'large'} />
            <ProductDetailItemSkeleton size={'large'} />
        </StyledMobileInfoContainer>
    );
};

export const ProductDetailsInformationSkeleton: FC<PropsWithChildren<PropsWithChildren>> = () => {
    return (
        <>
            <StyledProductDetailsInformation />
            <MobileProductDetailsInformationSkeleton />
        </>
    );
};
