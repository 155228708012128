import {IProductFragment} from 'autogen/IProductFragment';
import {
    FeaturedCollectionQuery,
    FeaturedCollectionQuery_pageableProductGroupStoreProducts_productGroupProducts
} from 'autogen/FeaturedCollectionQuery';
import {captureSentryError} from 'client/services/sentry-service';
import {removeDuplicateProducts} from 'client/utils/product-helpers';

import ProductSwimlaneOptions from '../../swimlanes/product-swimlane-options';

const COMPONENT = 'featured-collection-swimlane';

export default class FeaturedCollectionSwimlaneOptions extends ProductSwimlaneOptions<
    FeaturedCollectionQuery,
    IProductFragment
> {
    private readonly collectionId: number | null | undefined;

    private readonly seeAllOverride?: string | null;

    public constructor(collectionId: number | null | undefined, seeAllOverride?: string | null) {
        super();
        this.collectionId = collectionId;
        this.seeAllOverride = seeAllOverride;
    }

    public getHeader = (data: FeaturedCollectionQuery | undefined): string => {
        return data?.productGroup?.name ?? 'Featured Collection';
    };

    public getNextPage = (data: FeaturedCollectionQuery): number | null | undefined => {
        return data?.pageableProductGroupStoreProducts?.nextPage;
    };

    public getItems = (data: FeaturedCollectionQuery | undefined): IProductFragment[] => {
        const productGroupProducts: FeaturedCollectionQuery_pageableProductGroupStoreProducts_productGroupProducts[] =
            data?.pageableProductGroupStoreProducts?.productGroupProducts || [];

        const products = productGroupProducts
            .map((p) => p?.product)
            .filter((product) => Boolean(product)) as IProductFragment[];
        const productsWithStoreProducts = products.filter((product) => product.storeProduct);

        return removeDuplicateProducts(productsWithStoreProducts);
    };

    public mergePageResults = (
        previousData: FeaturedCollectionQuery,
        {fetchMoreResult}: {fetchMoreResult: FeaturedCollectionQuery}
    ): FeaturedCollectionQuery => {
        let previous = previousData?.pageableProductGroupStoreProducts?.productGroupProducts;
        let newValues = fetchMoreResult?.pageableProductGroupStoreProducts?.productGroupProducts;

        if (previous === undefined) {
            captureSentryError({
                component: COMPONENT,
                message: 'Error fetching previous page'
            });
            previous = [];
        }

        if (newValues === undefined) {
            captureSentryError({
                component: COMPONENT,
                message: 'Error fetching next page'
            });
            newValues = [];
        }

        const updated = [...previous, ...newValues];

        return {
            ...fetchMoreResult,
            pageableProductGroupStoreProducts: {
                __typename: 'pageableProductGroupProducts',
                nextPage: fetchMoreResult?.pageableProductGroupStoreProducts?.nextPage || null,
                page: fetchMoreResult?.pageableProductGroupStoreProducts?.page || 1,
                productGroupProducts: updated
            }
        };
    };

    public getSeeAllUrl = () => {
        if (this.seeAllOverride) {
            return this.seeAllOverride;
        }

        return this.collectionId ? `/aisles-online/collections/${this.collectionId}` : '';
    };

    public onError = (error) =>
        captureSentryError({
            component: COMPONENT,
            error,
            message: 'Error querying for featured collection swimlane data'
        });
}
