import {FC, PropsWithChildren} from 'react';

import {IFeaturedCollectionPromo} from '../home-page/types';
import {FeaturedCollectionSwimlane} from '../home-page/swimlanes';

interface IFeaturedCollectionProps {
    collectionId: number;
    promo?: IFeaturedCollectionPromo;
    lazyload?: boolean;
}

const SanityCollectionSwimlane: FC<PropsWithChildren<PropsWithChildren<IFeaturedCollectionProps>>> = (props) => {
    const {collectionId, lazyload, promo} = props;

    return <FeaturedCollectionSwimlane collectionId={collectionId} lazyload={lazyload} promo={promo} />;
};

export default SanityCollectionSwimlane;
