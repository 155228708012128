import {FC, PropsWithChildren} from 'react';

import {ElementProps} from '../../../../types/html-element-props';

import ProductDetailItemsSkeleton from './product-details-items-skeleton';
import {ProductDetailsInformationSkeleton} from './product-details-information-skeleton';
import SwimlaneSkeleton from './swimlane-skeleton';
import styles from './product-details-skeleton.module.css';

const ImageAndDetailsContainer: FC<PropsWithChildren<PropsWithChildren<ElementProps<HTMLDivElement>>>> = (props) => (
    <div {...props} className={styles.imageAndDetailsContainer} />
);

const ImageContainer: FC<PropsWithChildren<PropsWithChildren<ElementProps<HTMLDivElement>>>> = (props) => (
    <div {...props} className={styles.imageContainer} />
);

const ImageSkeleton: FC<PropsWithChildren<PropsWithChildren<ElementProps<HTMLDivElement>>>> = (props) => (
    <div {...props} className={styles.imageSkeleton} />
);

const ProductDetailsContainer: FC<PropsWithChildren<PropsWithChildren<ElementProps<HTMLDivElement>>>> = (props) => (
    <div {...props} className={styles.productDetailsContainer} />
);

const StyledContentContainer: FC<PropsWithChildren<PropsWithChildren<ElementProps<HTMLDivElement>>>> = (props) => (
    <div {...props} className={styles.styledContentContainer} />
);

export const StyledBreadcrumbLink: FC<PropsWithChildren<PropsWithChildren<ElementProps<HTMLDivElement>>>> = (props) => (
    <div {...props} className={styles.styledBreadcrumbLink} />
);

export const ProductBreadcrumbSkeleton: FC<PropsWithChildren<PropsWithChildren>> = () => (
    <StyledBreadcrumbLink data-testid={'product-details-breadcrumb-skeleton'} />
);

export const ProductImageCarouselSkeleton: FC<PropsWithChildren<PropsWithChildren>> = () => {
    return (
        <ImageContainer>
            <ImageSkeleton data-testid={'product-details-image'} />
        </ImageContainer>
    );
};

const ProductDetailsSkeleton: FC<PropsWithChildren<PropsWithChildren>> = () => {
    return (
        <StyledContentContainer data-testid={'product-details-skeleton'}>
            <ProductBreadcrumbSkeleton />
            <ImageAndDetailsContainer>
                <ProductImageCarouselSkeleton />
                <ProductDetailsContainer>
                    <ProductDetailItemsSkeleton />
                </ProductDetailsContainer>
            </ImageAndDetailsContainer>
            <ProductDetailsInformationSkeleton />
            <SwimlaneSkeleton />
        </StyledContentContainer>
    );
};

export default ProductDetailsSkeleton;
