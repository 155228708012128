import {FC, PropsWithChildren, useEffect} from 'react';
import {GlobalNavigationHeader, updateGlobalNavigationAOCartQuantities} from '@hy-vee/global-navigation';

import {useReservationDrawer} from 'client/hooks/use-reservation-drawer';
import {IStore} from 'autogen/IStore';

import {
    handleGlobalNavigationDataChange,
    useGlobalNavigationHeaderCart,
    useGlobalNavigationStoreConfig
} from '../../utils/global-navigation-helpers';

interface IGlobalNavigationHeaderWrapperProps {
    hideCartFlyout?: Boolean;
    store: IStore;
}

export const GlobalNavigationHeaderWrapper: FC<
    PropsWithChildren<PropsWithChildren<IGlobalNavigationHeaderWrapperProps>>
> = ({hideCartFlyout, store}) => {
    const {setIsOpen: setIsReservationDrawerOpen} = useReservationDrawer();
    const {data: cart, error: cartError, loading: cartLoading} = useGlobalNavigationHeaderCart();
    const storeConfig = useGlobalNavigationStoreConfig(store, cart, setIsReservationDrawerOpen);

    useEffect(() => {
        if (cart && !cartError && !cartLoading) {
            updateGlobalNavigationAOCartQuantities(cart);
        }
    }, [cartError, cartLoading, cart]);

    return (
        <GlobalNavigationHeader
            hideCartFlyout={hideCartFlyout}
            onDataChange={handleGlobalNavigationDataChange}
            storeConfig={storeConfig}
        />
    );
};
