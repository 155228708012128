import {FULFILLMENT_HEADER} from '../enums/header-types';
import {useClassnames} from '../hooks/use-classnames';

import styles from './header-styles.module.css';

export const SkipNavigation = ({children, href, ...props}) => (
    <a {...props} className={styles.skipNavigation} data-testid={'skip-navigation'} href={href}>
        {children}
    </a>
);

export const HeaderChildren = (props) => {
    const {children, className, sticky, headerType, ...otherProps} = props;

    const headerChildrenClassName = useClassnames(styles, {
        fulfillment: sticky && headerType === FULFILLMENT_HEADER,
        globalNavigation: sticky && headerType !== FULFILLMENT_HEADER,
        [`${className}`]: Boolean(className)
    });

    return (
        <main {...otherProps} className={`${headerChildrenClassName}`} data-testid={'header-children'}>
            {children}
        </main>
    );
};
