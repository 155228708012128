import {useCustomer, useCustomerData, useCustomerUuid} from 'client/hooks/customer-hooks';
import {getRecommendationGroupsQuery} from 'client/graphql/queries/product-queries';
import ProdxAislesType from 'client/enums/ProdxAislesType';
import {useCustomerDataToGetLocationId} from 'client/hooks/location-hooks';
import {EWIC_STORES, PERKS_PRICING} from 'client/enums/feature-toggle-names';
import {useFeatureToggle} from 'client/context/feature-toggle';

import {useGraphqlSwimlane} from '../hooks/useGraphqlSwimlane';
import {
    GetRecommendationGroupsQuery,
    GetRecommendationGroupsQueryVariables
} from '../../../../../autogen/GetRecommendationGroupsQuery';

import RecommendationSwimlaneOptions from './recommendation-swimlane-options';
import HomePageProdxSwimlane from './home-page-prodx-swimlane';

const RECOMMENDATION_LIMIT = 4;

interface IRecommendationSwimlane {
    lazyload: boolean;
}

const RecommendationSwimlane = ({lazyload}: IRecommendationSwimlane) => {
    const customerUuid = useCustomerUuid();
    const {pickupLocationHasLocker, storeId} = useCustomerData();
    const {fuelSaverCardUuid} = useCustomer();
    const {featureEnabled, featuresEnabled} = useFeatureToggle();
    const perksPricingEnabled = featureEnabled(PERKS_PRICING);
    const {locationId, loading: locationLoading} = useCustomerDataToGetLocationId();

    const wicEnabled = featuresEnabled([EWIC_STORES]);

    const options = new RecommendationSwimlaneOptions();

    const variables = {
        customerUuid,
        itemEnabled: true,
        limit: RECOMMENDATION_LIMIT,
        locationIds: [locationId],
        perksPricingEnabled,
        pickupLocationHasLocker,
        storeId,
        targeted: Boolean(fuelSaverCardUuid),
        wicEnabled
    };

    const {data, loading, fetchMore} = useGraphqlSwimlane<
        GetRecommendationGroupsQuery,
        GetRecommendationGroupsQueryVariables
    >(options, getRecommendationGroupsQuery, {
        skip: !customerUuid || locationLoading,
        variables
    });

    return (
        <HomePageProdxSwimlane
            aislesId={ProdxAislesType.RECOMMENDATIONS}
            aria-label={options.getHeader()}
            data={options.getItems(data)}
            fetchMore={fetchMore}
            header={{
                seeAllUrl: options.getSeeAllUrl(),
                text: options.getHeader()
            }}
            lazyload={lazyload}
            loading={loading}
            swimlaneTestId={'recommendations-swimlane'}
        />
    );
};

export default RecommendationSwimlane;
