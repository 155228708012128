import Cookies from 'js-cookie';

import {FeaturedCollectionSlotIds} from 'client/views/components/home-page/types/FeaturedCollectionSlotIds';

const HOME_PAGE_FEATURED_COLLECTION = 'homePageFeaturedCollection';

const getFeaturedCollectionCookie = () => {
    const cookieStr = Cookies.get(HOME_PAGE_FEATURED_COLLECTION) || null;

    return cookieStr ? JSON.parse(cookieStr) : [];
};

export const getFeaturedCollectionId = (slotId: FeaturedCollectionSlotIds): number | undefined => {
    const value = getFeaturedCollectionCookie();

    return value[slotId];
};

export const setFeaturedCollectionId = (slotId: FeaturedCollectionSlotIds, collectionId: number): void => {
    const value = getFeaturedCollectionCookie();

    value[slotId] = collectionId;
    const inFiveMinutes = new Date(Date.now() + 5 * 60 * 1000);

    Cookies.set(HOME_PAGE_FEATURED_COLLECTION, JSON.stringify(value), {expires: inFiveMinutes});
};
