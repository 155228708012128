import {FC, PropsWithChildren} from 'react';
import styled from 'styled-components';
import {spacing, colors} from '@hy-vee/themes';

import {pulseBackground, pulseBorder} from 'client/styles/loading-skeletons';

const StyledContainer = styled.article`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-width: 450px;
    max-width: 455px;
    height: 300px;
    padding: ${spacing.medium};
    animation: ${pulseBorder} 1s infinite ease-in-out;
    background: #fff;
    border: 1px solid ${colors.grey[100]};
`;

const StyledCouponImage = styled.div`
    display: flex;
    height: 150px;
    margin-top: 16px;
    width: 150px;
    animation: ${pulseBackground} 1s infinite ease-in-out;
    background-color: ${colors.grey[100]};
`;

const StyledTextAndButtonWrapper = styled.div`
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    height: 300px;
    padding: 16px;
`;

const StyledTextWrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const StyledValueText = styled.div`
    display: flex;
    height: 40px;
    width: 150px;
    margin-bottom: 12px;
    animation: ${pulseBackground} 1s infinite ease-in-out;
    background-color: ${colors.grey[100]};
`;

const StyledDescriptionText = styled.div`
    display: flex;
    height: 60px;
    width: 200px;
    margin-bottom: 12px;
    animation: ${pulseBackground} 1s infinite ease-in-out;
    background-color: ${colors.grey[100]};
`;

const StyledEndDateAndDetailsText = styled.div`
    display: flex;
    height: 30px;
    width: 175px;
    animation: ${pulseBackground} 1s infinite ease-in-out;
    background-color: ${colors.grey[100]};
`;

const StyledButton = styled.div`
    height: 40px;
    width: 240px;
    margin-bottom: 24px;
    animation: ${pulseBackground} 1s infinite ease-in-out;
`;

const CouponCardSkeleton: FC<PropsWithChildren<PropsWithChildren<{height?: string}>>> = ({...props}) => (
    <StyledContainer aria-setsize={-1} {...props}>
        <StyledCouponImage />
        <StyledTextAndButtonWrapper>
            <StyledTextWrapper>
                <StyledValueText />
                <StyledDescriptionText />
                <StyledEndDateAndDetailsText />
            </StyledTextWrapper>
            <StyledButton />
        </StyledTextAndButtonWrapper>
    </StyledContainer>
);

export default CouponCardSkeleton;
