import {FC, PropsWithChildren, useMemo} from 'react';
import type {AppInitialProps} from 'next/app';
import type {Action, Dispatch} from 'redux';
import {Provider} from 'react-redux';

import {useUserDetails} from '../context/user-details';
import {useInitialCustomerState} from '../hooks/customer-hooks';
import {SET_AUTHENTICATION_SUCCESS, SET_CUSTOMER} from '../action-types';
import {GetCustomerData_customer} from '../../autogen/GetCustomerData';
import GoogleTagManagerWrapper from '../views/components/authentication/google-tag-manager-wrapper';
import ErrorBoundary from '../views/components/error-boundary';
import ServerRenderSEO from '../views/components/server-render-seo/server-render-seo';

import {getOrCreateStore} from './with-redux';

const setInitialState = (dispatch: Dispatch<Action>, customer: GetCustomerData_customer, isAuthenticated: boolean) => {
    dispatch({
        customer,
        type: SET_CUSTOMER
    });
    dispatch({
        isAuthenticated,
        type: SET_AUTHENTICATION_SUCCESS
    });
};

type WrappedPageComponent<T> = AppInitialProps['pageProps'] & T;

/**
 * To be able to statically render past the loading component you are going to have to set up
 * [fallback data using SWRConfig]{@link https://swr.vercel.app/docs/with-nextjs#pre-rendering-with-default-data}
 * for {@link useUserDetails} and customer data in Apollo for {@link useInitialCustomerState}
 * via getStaticProps/getStaticPaths on the page
 *
 * @param WrappedPageComponent The Page to wrap
 * @param PageLoadingComponent The Page Loading Skeleton
 */
const withDynamicStatic = <T,>(
    WrappedPageComponent: FC<PropsWithChildren<PropsWithChildren<WrappedPageComponent<T>>>>,
    PageLoadingComponent: FC
): FC<PropsWithChildren<PropsWithChildren<WrappedPageComponent<T>>>> => {
    const Wrapper: FC<PropsWithChildren<PropsWithChildren<WrappedPageComponent<T>>>> = (props) => {
        const {isAuthenticated} = useUserDetails();
        const {data, error, loading: customerLoading} = useInitialCustomerState();
        const reduxStore = useMemo(() => getOrCreateStore(props.reduxState), [props.reduxState]);
        const serverRenderSeo = useMemo(() => props.serverRenderSeo, [props.serverRenderSeo]);

        if (customerLoading) {
            return (
                <>
                    <ServerRenderSEO seo={serverRenderSeo} />
                    <PageLoadingComponent />
                </>
            );
        }

        if (!error && data?.customer) {
            setInitialState(reduxStore.dispatch, data.customer, isAuthenticated);
        }

        return (
            <Provider store={reduxStore}>
                <GoogleTagManagerWrapper>
                    <ErrorBoundary>
                        <ServerRenderSEO seo={serverRenderSeo} />
                        <WrappedPageComponent {...props} />
                    </ErrorBoundary>
                </GoogleTagManagerWrapper>
            </Provider>
        );
    };

    Wrapper.displayName = `${WrappedPageComponent.displayName}WithStaticStateManagement`;

    return Wrapper;
};

export {withDynamicStatic};
