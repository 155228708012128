import {captureSentryError} from 'client/services/sentry-service';

import {ICouponV4} from '../../../../../autogen/ICouponV4';
import {GetCouponsForCouponSwimlane} from '../../../../../autogen/GetCouponsForCouponSwimlane';
import SwimlaneOptions from '../../swimlanes/swimlane-options';

export default class CouponSwimlaneOptions extends SwimlaneOptions<GetCouponsForCouponSwimlane, ICouponV4> {
    public coupons: any[];

    private readonly header: string;

    public constructor(header: string) {
        super();
        this.header = header;
    }

    public getHeader = () => this.header;

    public getNextPage = (): null => {
        return null;
    };

    public getItems = (data: GetCouponsForCouponSwimlane | undefined): ICouponV4[] =>
        data?.couponsV4 ? data.couponsV4 : [];

    public mergePageResults = () => ({
        couponsV4: []
    });

    public getSeeAllUrl = () => '/deals/coupons.aspx';

    public onError = (error) =>
        captureSentryError({
            component: 'coupon-swimlane',
            error,
            message: 'Error querying for coupon swimlane data'
        });
}
