import {FC, PropsWithChildren} from 'react';

import {useClassnames} from '../../../../hooks/use-classnames';
import {ElementProps} from '../../../../types/html-element-props';

import styles from './product-details-items-skeleton.module.css';

const DetailsContainer: FC<PropsWithChildren<PropsWithChildren<ElementProps<HTMLDivElement>>>> = (props) => (
    <div {...props} className={styles.detailsContainer} />
);

const StyledButtonRow: FC<PropsWithChildren<PropsWithChildren<ElementProps<HTMLDivElement>>>> = (props) => (
    <div {...props} className={styles.styledButtonRow} />
);

const StyledButtonSkeleton: FC<PropsWithChildren<PropsWithChildren<ElementProps<HTMLDivElement>>>> = (props) => (
    <div {...props} className={styles.styledButtonSkeleton} />
);

interface IProductDetailItemSkeletonProps extends ElementProps<HTMLDivElement> {
    size: 'extraSmall' | 'large' | 'medium' | 'small';
}

export const ProductDetailItemSkeleton: FC<PropsWithChildren<PropsWithChildren<IProductDetailItemSkeletonProps>>> = ({
    size,
    ...props
}) => {
    const classnames = useClassnames(styles, {
        productDetailItemSkeleton: true,
        [`${size}`]: true
    });

    return <div {...props} className={classnames} data-testid={'product-details-item'} />;
};

const ProductDetailItemsSkeleton = () => {
    return (
        <DetailsContainer data-testid={'product-detail-information-container'}>
            <ProductDetailItemSkeleton size={'medium'} />
            <ProductDetailItemSkeleton size={'large'} />
            <ProductDetailItemSkeleton size={'extraSmall'} />
            <ProductDetailItemSkeleton size={'large'} />
            <ProductDetailItemSkeleton size={'small'} />
            <StyledButtonRow>
                <StyledButtonSkeleton data-testid={'product-details-add-to-cart-button'} />
                <StyledButtonSkeleton data-testid={'product-details-add-to-list-button'} />
            </StyledButtonRow>
        </DetailsContainer>
    );
};

export default ProductDetailItemsSkeleton;
