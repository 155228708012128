import {gql} from '@apollo/client';

export const getBrandLandingPageSEO = gql`
    query getBrandLandingPageSEO($slugFilter: SlugFilter!) {
        allCmsSharedBrandLandingPage(where: {slug: $slugFilter}) {
            seo {
                pageTitle
                pageDescription
                pageImage {
                    asset {
                        url
                    }
                }
                pageImageUrl
            }
        }
    }
`;

export const getBrandLandingPage = gql`
    query getBrandLandingPage($slugFilter: SlugFilter!) {
        allCmsSharedBrandLandingPage(where: {slug: $slugFilter}) {
            name
            body {
                ... on CmsImageCollection {
                    _type
                    images {
                        image {
                            alt
                            asset {
                                url
                            }
                        }
                    }
                    maxImages
                }

                ... on CmsCardCollection {
                    _type
                    cards {
                        ... on CmsVerticalCard {
                            _type
                            image {
                                alt
                                asset {
                                    url
                                }
                            }
                            backgroundColor {
                                hex
                            }
                            videoUrl
                            bodyTextRaw
                            buttons {
                                ... on CmsSimpleButton {
                                    _type
                                    buttonText
                                    href
                                    type
                                }
                            }
                        }

                        ... on CmsHorizontalCard {
                            _type
                            image {
                                alt
                                asset {
                                    url
                                }
                            }
                            backgroundColor {
                                hex
                            }
                            headerText
                            imageSide
                            videoUrl
                            bodyTextRaw
                            buttons {
                                ... on CmsSimpleButton {
                                    _type
                                    buttonText
                                    href
                                    type
                                }
                            }
                        }
                    }
                    textAlignment
                    maxMobileCards
                    backgroundColor {
                        hex
                    }
                    textColor {
                        hex
                    }
                }

                ... on CmsHeroBanner {
                    _type
                    tacticId
                    backgroundImage {
                        alt
                        asset {
                            url
                        }
                    }
                    tabletBackgroundImage {
                        alt
                        asset {
                            url
                        }
                    }
                    mobileBackgroundImage {
                        alt
                        asset {
                            url
                        }
                    }
                    backgroundVideoUrl
                    backgroundVideoAlt
                    header
                    subheader
                    logo {
                        alt
                        asset {
                            url
                        }
                    }
                    mediaHeight
                    mediaWidth
                    buttons {
                        ... on CmsHeroBannerButton {
                            _type
                            buttonText
                            buttonLink
                        }
                    }
                }

                ... on CmsTextBlock {
                    _type
                    textRaw
                    maxColumns
                    horizontalDivider
                    verticalDivider
                    textAlignment
                    backgroundColor {
                        hex
                    }
                    textColor {
                        hex
                    }
                }

                ... on CmsProductCollection {
                    _type
                    collectionId
                    promo {
                        enabled
                        heading
                        imageUrl
                        linkText
                        linkUrl
                        subtext
                    }
                    backgroundColor {
                        hex
                    }
                }

                ... on CmsDecoration {
                    _type
                    decorationName
                }
            }
        }
    }
`;
