import {FC, PropsWithChildren} from 'react';

import AislesOnlineLink from '../../aisles-online-link';
import ProductSwimlane, {IProductSwimlaneProps} from '../../swimlanes/product-swimlane';
import AislesOnlineLinkTypes from '../../aisles-online-link-types';

import {StyledHeader, SwimLaneHeader, SwimlaneWrapper} from './home-page-swimlane-styles';

interface IHeaderProps {
    text: string;
    seeAllUrl?: string;
}

const Header: FC<PropsWithChildren<PropsWithChildren<IHeaderProps>>> = ({text, seeAllUrl}) => {
    return (
        <SwimLaneHeader>
            <StyledHeader as="h2" tabIndex={0}>
                {text}
            </StyledHeader>
            <AislesOnlineLink
                classLink={AislesOnlineLinkTypes.SWIMLANE_HEADER_LINK}
                linkUrl={seeAllUrl}
                overrideLinkStyles
                testId="swimlane-see-all"
            >
                {'See All'}
            </AislesOnlineLink>
        </SwimLaneHeader>
    );
};

interface IHomePageSwimlaneProps extends IProductSwimlaneProps {
    id?: string;
    style?: {
        maxWidth: string;
    };
    header: IHeaderProps;
}

const SwimlaneWithHeader: FC<PropsWithChildren<PropsWithChildren<IHomePageSwimlaneProps>>> = (props) => {
    return (
        <SwimlaneWrapper id={props.id} style={props.style}>
            <ProductSwimlane {...props} HeaderComponent={<Header {...props.header} />} />
        </SwimlaneWrapper>
    );
};

export default SwimlaneWithHeader;
