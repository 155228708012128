export const updateQuery =
    (pageableFieldName: string, nestedFieldName: string) =>
    (previousData: any, {fetchMoreResult}: {fetchMoreResult: any}): any => {
        const previousPageable = previousData[pageableFieldName];
        const newPageable = fetchMoreResult[pageableFieldName];
        const previous = previousPageable[nestedFieldName];
        const newValues = newPageable[nestedFieldName];
        const updated = [...previous, ...newValues];

        return {
            [pageableFieldName]: {
                __typename: pageableFieldName,
                [nestedFieldName]: updated,
                nextPage: newPageable.nextPage,
                page: newPageable.page
            }
        };
    };
