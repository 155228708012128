import {FC, useEffect, useRef, useState, Fragment, PropsWithChildren, useCallback, useMemo} from 'react';
import LazyLoad, {forceCheck} from 'react-lazyload';

import {reportProductImpression, reportSwimlaneProductImpression} from 'client/services/analytics-service';

import ScrollIcon from './scroll-icon';
import IntersectWrapper from './intersect-wrapper';
import {
    StyledDrawer,
    StyledProductCardsContainer,
    SwimlaneLoading,
    SwimLaneContainer,
    ItemsContainer
} from './swimlane-styles';

interface ISwimlaneProps {
    childComponentRenderer: (data: any | null, index?: number) => JSX.Element;
    PromotionComponent?: JSX.Element;
    swimlaneTestId: string;
    LoadingComponent: FC<PropsWithChildren<PropsWithChildren>>;
    HeaderComponent?: JSX.Element;
    'aria-label': string;
    swimlaneDrawerStyles?: object;
    notifyVisibility?: (val: boolean) => void;
    onSwimlaneView?: (products: any[] | null) => void;
    EmptyResultsComponent?: JSX.Element;
    data: any[];
    loading: boolean;
    fetchMore?: () => Promise<any>;
}

const PAGE_SIZE = 10;

const getItemId = (item) => item?.productId ?? item?.couponId ?? item?.heading;

const Swimlane: FC<PropsWithChildren<PropsWithChildren<ISwimlaneProps>>> = (props) => {
    const {
        childComponentRenderer,
        PromotionComponent,
        swimlaneTestId,
        HeaderComponent,
        LoadingComponent,
        swimlaneDrawerStyles = {},
        notifyVisibility,
        onSwimlaneView,
        data,
        loading,
        fetchMore,
        EmptyResultsComponent
    } = props;

    const [isFetchingMore, setFetchingMore] = useState(false);
    const swimLaneRef = useRef(null);
    const [largestVisibleIndex, setLargestVisibleIndex] = useState(-1);

    const items = useMemo(() => data ?? [], [data]);

    useEffect(() => {
        if (data && onSwimlaneView) {
            onSwimlaneView(data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const onVisible = useCallback(
        (index, item) => {
            if (item.productId) {
                reportSwimlaneProductImpression({
                    index,
                    products: items,
                    swimlaneTestId
                });

                reportProductImpression(item, swimlaneTestId, index);
            }

            if (index > largestVisibleIndex) {
                setLargestVisibleIndex(index);
            }
        },
        [items, swimlaneTestId, largestVisibleIndex]
    );

    const shouldReturnNull = !loading && !items.length && !EmptyResultsComponent;
    const shouldReturnLoading = loading && !isFetchingMore;
    const shouldFetchMore = !loading && !isFetchingMore && items.length > 0 && items.length - largestVisibleIndex < 5;

    useEffect(() => {
        if (shouldFetchMore && fetchMore) {
            setFetchingMore(true);

            fetchMore().finally(() => {
                setFetchingMore(false);
            });
        }
    }, [fetchMore, shouldFetchMore]);

    useEffect(() => {
        if (shouldReturnNull) {
            forceCheck();
        }
    });

    if (notifyVisibility) {
        notifyVisibility(!shouldReturnNull);
    }

    if (shouldReturnNull) {
        return null;
    } else if (shouldReturnLoading) {
        return <SwimlaneLoading LoadingComponent={LoadingComponent} count={PAGE_SIZE} />;
    } else if (!items.length && EmptyResultsComponent) {
        return EmptyResultsComponent;
    }

    const label = props['aria-label'];

    return (
        <StyledProductCardsContainer>
            {HeaderComponent}
            <StyledDrawer
                aria-busy={isFetchingMore ? 'true' : 'false'}
                aria-label={label}
                id={`swimlane-drawer-${label}`}
                ref={swimLaneRef}
                role="feed"
                style={{...swimlaneDrawerStyles}}
            >
                <ScrollIcon direction="left" ref={swimLaneRef} />
                <ScrollIcon direction="right" ref={swimLaneRef} />

                <ItemsContainer>
                    {PromotionComponent}
                    {items.map((item, index) => (
                        <IntersectWrapper
                            data-testid={
                                item.productId ? `product-card-${item.productId}` : `coupon-card-${item.couponId}`
                            }
                            key={getItemId(item)}
                            onVisible={() => onVisible(index, item)}
                        >
                            <LazyLoad
                                offset={[250, 200]}
                                once
                                overflow
                                placeholder={<LoadingComponent />}
                                resize
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center'
                                }}
                            >
                                {childComponentRenderer(item, index)}
                            </LazyLoad>
                        </IntersectWrapper>
                    ))}
                    {isFetchingMore ? (
                        <>
                            {[...new Array(PAGE_SIZE).keys()].map((val) => (
                                <LoadingComponent data-testid={`fetch-more-loading-${val}`} key={val} />
                            ))}
                        </>
                    ) : null}
                </ItemsContainer>
            </StyledDrawer>
        </StyledProductCardsContainer>
    );
};

export interface ISwimlaneLazyLoadProps extends ISwimlaneProps {
    lazyload?: boolean;
    overflow?: boolean;
}

const SwimlaneLazyload: FC<PropsWithChildren<PropsWithChildren<ISwimlaneLazyLoadProps>>> = ({
    lazyload,
    overflow,
    ...props
}) => {
    const Wrapper = lazyload ? LazyLoad : Fragment;

    const wrapperProps = lazyload
        ? {
              offset: 150,
              once: true,
              overflow,
              placeholder: <SwimlaneLoading LoadingComponent={props.LoadingComponent} count={15} />
          }
        : {};

    return (
        <SwimLaneContainer data-testid={props.swimlaneTestId}>
            <Wrapper {...wrapperProps}>
                <Swimlane {...props} />
            </Wrapper>
        </SwimLaneContainer>
    );
};

export default SwimlaneLazyload;
