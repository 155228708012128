import {useEffect, useRef, useState} from 'react';

const useIntersect = (): [any, {isIntersecting: boolean}] => {
    const [entry, updateEntry] = useState<any>({});
    const [node, setNode] = useState(null);

    const observer = useRef(
        new window.IntersectionObserver(([e]) => updateEntry(e), {
            threshold: [0.5, 1]
        })
    );

    useEffect(() => {
        const {current: currentObserver} = observer;

        currentObserver.disconnect();

        if (node) currentObserver.observe(node);

        return () => currentObserver.disconnect();
    }, [node]);

    return [setNode, entry];
};

export default useIntersect;
