import {LoyaltyCardAccountName} from 'autogen/globalTypes';
import {IFuelSaverCard_accounts} from 'autogen/IFuelSaverCard';

export const getRewardBalance = (fuelSaverAccount: IFuelSaverCard_accounts | null): string =>
    fuelSaverAccount && fuelSaverAccount.rewardBalance ? fuelSaverAccount.rewardBalance.toFixed(2) : '0';

export const getLoyaltyCardAccountByName = (
    fuelSaverCard,
    accountName: LoyaltyCardAccountName
): IFuelSaverCard_accounts => fuelSaverCard.accounts.find((account) => account.accountName === accountName);

export const getFormattedFuelSaverAmount = (fuelSaverBalance: number, centSign = true): string => {
    if (fuelSaverBalance < 1) {
        if (centSign) {
            return `${(fuelSaverBalance * 100).toFixed(0)}¢`;
        }

        return `${(fuelSaverBalance * 100).toFixed(0)}`;
    }

    return `$${fuelSaverBalance.toFixed(2)}`;
};

export const calculateHPlusRewards = (total: number) => {
    if (total >= 50 && total < 100) {
        return 0.1;
    } else if (total >= 100 && total < 200) {
        return 0.25;
    } else if (total >= 200 && total < 300) {
        return 0.5;
    } else if (total >= 300 && total < 400) {
        return 0.75;
    } else if (total >= 400) {
        return 1;
    }

    return 0;
};
