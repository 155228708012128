import {DocumentNode, OperationVariables, QueryHookOptions, TypedDocumentNode, useQuery} from '@apollo/client';

import SwimlaneOptions from '../../swimlanes/swimlane-options';

type IPageable = OperationVariables & {
    pageSize?: number | null;
    searchInput?: {
        pageInfoInput?: {
            page?: number | null;
        } | null;
    };
};

export const useGraphqlSwimlane = <TData = any, TVariables extends IPageable = IPageable>(
    swimlaneOption: SwimlaneOptions<TData, any>,
    query: DocumentNode | TypedDocumentNode<TData, TVariables>,
    options?: QueryHookOptions<TData, TVariables>
) => {
    const {error, data, loading, fetchMore} = useQuery<TData, IPageable>(query, {
        ...options,
        variables: {
            pageSize: 10,
            ...options?.variables
        }
    });

    const fetchMoreWrapper = () => {
        const nextPage = data ? swimlaneOption.getNextPage(data) : null;

        if (nextPage) {
            const pageVariable: IPageable = options?.variables?.searchInput
                ? {
                      searchInput: {
                          ...options.variables.searchInput,
                          pageInfoInput: {
                              ...options.variables.searchInput.pageInfoInput,
                              page: nextPage
                          }
                      }
                  }
                : {page: nextPage};

            return async () => {
                return fetchMore({
                    updateQuery: swimlaneOption.mergePageResults,
                    variables: {
                        ...pageVariable
                    }
                });
            };
        }

        return undefined;
    };

    return {
        data,
        error,
        fetchMore: fetchMoreWrapper(),
        loading
    };
};
