import {useQuery} from '@apollo/client';

import {getSubNav} from 'client/graphql/queries/get-sub-nav';
import {graphqlClient} from 'client/graphql/graphql-client';
import {TWO_LEGGED_AUTH_TYPE} from 'client/enums/auth-types';
import {GetSubNav, GetSubNav_allCmsSharedSubNavigationConfig} from 'autogen/GetSubNav';

interface IUseSubNav {
    data: GetSubNav_allCmsSharedSubNavigationConfig | null;
    loading: boolean;
}

export const useSubNav = (): IUseSubNav => {
    const {data, loading} = useQuery<GetSubNav>(getSubNav, {
        client: graphqlClient(),
        variables: {
            authType: TWO_LEGGED_AUTH_TYPE
        }
    });

    return {
        data: data?.allCmsSharedSubNavigationConfig?.[0] || null,
        loading
    };
};
