import {ApolloError} from '@apollo/client';

export default abstract class SwimlaneOptions<T, S> {
    public abstract getHeader: (data?: T) => string;

    public abstract getNextPage: (data: T) => number | null | undefined;

    public abstract getItems: (data: T) => S[];

    public abstract mergePageResults: ((previousData: T, {fetchMoreResult}: {fetchMoreResult: T}) => T) | undefined;

    public abstract getSeeAllUrl: (data: T) => string | undefined;

    public abstract onError: (error: ApolloError) => void;
}
